import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IAltBilesenForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IAltBilesenForm_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	AltBilesenById: any[];
	AltBilesenById_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;


}

export class AltBilesenForm_ScreenBase extends React.PureComponent<IAltBilesenForm_ScreenProps, any> {

    ml=[{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Fitness Sub_Component","TagName":"lblHead_value"},{"Id":6210065,"PropertyName":"value","Value":"Sub Component Description","TagName":"lblTanim_value"},{"Id":3517934,"PropertyName":"placeholder","Value":"Type here...","TagName":"txtTanim_placeholder"},{"Id":9544,"PropertyName":"label","Value":"Save","TagName":"btnSave_label"}]},{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","LanguagePhrases":[]},{"Id":"4f7293c6-4ae0-4e10-87a1-1d7265a91549","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Fitness ALT BİLEŞENİ"},{"Id":6210065,"PropertyName":"value","Value":"Alt Bileşen Tanımı"},{"Id":3517934,"PropertyName":"placeholder","Value":"Type here..."},{"Id":9544,"PropertyName":"label","Value":"Kaydet"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        AltBilesenById: [],
	        SaveRecord: false,

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("altbilesenform", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.AltBilesenFormPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("altbilesenform", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("altbilesenform", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.AltBilesenFormPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
altbilesenform_3517934_value: this.state.AltBilesenById?.at?.(0)?.tanim ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    AltBilesenFormPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.AltBilesenId ?? this.props.screenInputs.altbilesenid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "AltBilesenForm/AltBilesenFormPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.AltBilesenById = result?.data.altBilesenById;
		formVars.altbilesenform_3517934_value = ReactSystemFunctions.toString(this, stateVars.AltBilesenById?.length > 0 ? stateVars.AltBilesenById[0]?.tanim : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.AltBilesenFormPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	AltBilesenFormPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.altbilesenform_3517934_value = ReactSystemFunctions.toString(this, this.state.AltBilesenById?.length > 0 ? this.state.AltBilesenById[0]?.tanim : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		AltBilesenFormComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    AltBilesenFormComponent_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.AltBilesenId ?? this.props.screenInputs.altbilesenid, "Guid"),
				Tanim_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "altbilesenform_3517934_value", "value", "", "", "")), "string"),
				BilesenId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.BilesenId ?? this.props.screenInputs.bilesenid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "AltBilesenForm/AltBilesenFormComponent_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.AltBilesenFormComponent_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		AltBilesenFormComponent_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
