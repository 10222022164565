import * as React from "react";
import { TestDeneme2Test_ScreenBase } from "./testdeneme2test-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer } from "../../redux/photo-take/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";




declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class TestDeneme2Test_Screen extends TestDeneme2Test_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                    <Form form={this.props.form} name="basic"
                        initialValues={
                            {testdeneme2test_476469_value: undefined,
							testdeneme2test_209326_value: undefined,
							testdeneme2test_556057_value: undefined,
							testdeneme2test_227743_value: undefined,
							testdeneme2test_835245_value: undefined,
							testdeneme2test_242650_value: undefined,
							testdeneme2test_849550_value: undefined,
							testdeneme2test_951285_value: 3,
							testdeneme2test_626153_value: undefined,
							testdeneme2test_549317_value: undefined,
							testdeneme2test_572033_value: undefined,
							testdeneme2test_636352_value: undefined,
							testdeneme2test_239641_value: undefined,
							testdeneme2test_274316_value: undefined,
							testdeneme2test_60614_value: undefined
}
                        }>
                        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
<Header id="testdeneme2test_header" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left"} as any}>
<KRow id="472882" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="622344" xs={21} sm={21} md={21} lg={21} xl={21} xxl={21} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="796540" value={ReactSystemFunctions.translate(this.ml, 796540, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"18pt","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>

<KCol id="968985" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="968274" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.TestDeneme2TestComponent_968274_onClick()} } showCursorPointer iconName="close" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>
</KRow>
</Header>

<div id="testdeneme2test_body" style={{"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block"} as any}>
<KRow id="176430" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":16,"paddingRight":16,"paddingBottom":0,"paddingLeft":16,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="302640" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="895971" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":32,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="676062" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="8761400" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="2883001" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="5259063" value={ReactSystemFunctions.translate(this.ml, 5259063, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_476469_value' >
<TextInput id="476469" placeholder={ReactSystemFunctions.translate(this.ml, 476469, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="354581" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="741210" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="701867" value={ReactSystemFunctions.translate(this.ml, 701867, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_209326_value' >
<TextInput id="209326" placeholder={ReactSystemFunctions.translate(this.ml, 209326, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="6956913" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="4879466" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="6858922" value={ReactSystemFunctions.translate(this.ml, 6858922, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_556057_value' >
<KSelectBox id="556057" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.TestDeneme2TestComponent_556057_onChange()} } kuikaRef={this.testdeneme2test_556057_value_kuikaSelectBoxRef} options={this.state.BilesenAll} placeholder={ReactSystemFunctions.translate(this.ml, 556057, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='id' datatextfield='tanim' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="238530" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="983980" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="765773" value={ReactSystemFunctions.translate(this.ml, 765773, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_227743_value' >
<KSelectBox id="227743" kuikaRef={this.testdeneme2test_227743_value_kuikaSelectBoxRef} options={this.state.AltBilesenByBilesenId} placeholder={ReactSystemFunctions.translate(this.ml, 227743, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='id' datatextfield='tanim' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>

<KCol id="670580" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="990198" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="113959" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="393960" value={ReactSystemFunctions.translate(this.ml, 393960, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_835245_value' >
<KSelectBox id="835245" kuikaRef={this.testdeneme2test_835245_value_kuikaSelectBoxRef} options={this.state.dataSource_835245} placeholder={ReactSystemFunctions.translate(this.ml, 835245, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='key' datatextfield='text' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>

<KCol id="757159" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="390090" value={ReactSystemFunctions.translate(this.ml, 390090, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_242650_value' >
<KSelectBox id="242650" kuikaRef={this.testdeneme2test_242650_value_kuikaSelectBoxRef} options={this.state.dataSource_242650} placeholder={ReactSystemFunctions.translate(this.ml, 242650, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='key' datatextfield='text' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="351651" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="141661" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="193587" value={ReactSystemFunctions.translate(this.ml, 193587, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_849550_value' >
<KSelectBox id="849550" kuikaRef={this.testdeneme2test_849550_value_kuikaSelectBoxRef} options={this.state.dataSource_849550} placeholder={ReactSystemFunctions.translate(this.ml, 849550, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='key' datatextfield='text' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>

<KCol id="531826" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="124606" value={ReactSystemFunctions.translate(this.ml, 124606, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_951285_value' >
<Rating id="951285" allowHalf={false} count={5} onBlur={{}} onChange={{}} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0} as any}></Rating>
</Form.Item>
</KCol>
</KRow>

<KRow id="217153" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="74686" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="975857" value={ReactSystemFunctions.translate(this.ml, 975857, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_626153_value' >
<KSelectBox id="626153" kuikaRef={this.testdeneme2test_626153_value_kuikaSelectBoxRef} options={this.state.PopulasyonAll} placeholder={ReactSystemFunctions.translate(this.ml, 626153, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='id' datatextfield='tanim' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>

<KCol id="355242" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="838865" value={ReactSystemFunctions.translate(this.ml, 838865, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_549317_value' >
<KSelectBox id="549317" kuikaRef={this.testdeneme2test_549317_value_kuikaSelectBoxRef} options={this.state.dataSource_549317} placeholder={ReactSystemFunctions.translate(this.ml, 549317, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='key' datatextfield='text' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="264054" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="683149" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="615895" value={ReactSystemFunctions.translate(this.ml, 615895, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_572033_value' >
<MultiSelect id="572033" kuikaRef={this.testdeneme2test_572033_value_kuikaMultiSelectRef} options={this.state.dataSource_572033} placeholder="Seçiniz..." allowClear={false} autoClearSearchValue={true} mode="tags" showSearch={true} autoRefresh={true} datavaluefield='key' datatextfield='text' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></MultiSelect>
</Form.Item>
</KCol>

<KCol id="60815" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="892503" value={ReactSystemFunctions.translate(this.ml, 892503, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_636352_value' >
<KSelectBox id="636352" kuikaRef={this.testdeneme2test_636352_value_kuikaSelectBoxRef} options={this.state.dataSource_636352} placeholder={ReactSystemFunctions.translate(this.ml, 636352, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='key' datatextfield='text' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="687036" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="222345" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="128097" value={ReactSystemFunctions.translate(this.ml, 128097, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<KRow id="566666" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="953767" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":4,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='testdeneme2test_239641_value' >
<NumberInput id="239641" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 239641, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"100px","alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</KCol>

<KCol id="632861" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingLeft":4,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='testdeneme2test_274316_value' >
<NumberInput id="274316" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 274316, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"100px","alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</KCol>
</KRow>
</KCol>

<KCol id="206525" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="872570" value={ReactSystemFunctions.translate(this.ml, 872570, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='testdeneme2test_60614_value' >
<KSelectBox id="60614" kuikaRef={this.testdeneme2test_60614_value_kuikaSelectBoxRef} options={this.state.dataSource_60614} placeholder={ReactSystemFunctions.translate(this.ml, 60614, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} onSearch={{}} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='key' datatextfield='text' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</div>

<Footer id="testdeneme2test_footer" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left"} as any}>
<KRow id="841691" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="600721" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Button id="9544" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.TestDeneme2TestComponent_9544_onClick()} } showCursorPointer label={ReactSystemFunctions.translate(this.ml, 9544, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" icon="keyboard_return" iconPosition="right" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>
</KCol>
</KRow>
</Footer>
</div>
                    </Form>
                </Spin>
              
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that))
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(TestDeneme2Test_Screen))))));
export { tmp as TestDeneme2Test_Screen };
//export default tmp;
//export { tmp as TestDeneme2Test_Screen };

