import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMySchedule_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMySchedule_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	MyContext: any[];
	MyContext_dummy: any[];
	RandevularimDanisan: any[];
	RandevularimDanisan_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class MySchedule_ScreenBase extends React.PureComponent<IMySchedule_ScreenProps, any> {
	myschedule_8148818_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"0369a482-e89c-4172-9cd9-76f3f25336ca","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":54307,"PropertyName":"value","Value":"RANDEVULARIM"},{"Id":690363,"PropertyName":"label","Value":"YENİ RANDEVU"},{"Id":8148818,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":2908459,"PropertyName":"title","Value":"Hizmet Adı"},{"Id":4833407,"PropertyName":"value","Value":"[datafield:hizmetadi]"},{"Id":1599874,"PropertyName":"title","Value":"Kurum Adı"},{"Id":5995405,"PropertyName":"value","Value":"[datafield:kurumadi]"},{"Id":7030046,"PropertyName":"title","Value":"Eğitmen Adı"},{"Id":1111921,"PropertyName":"value","Value":"[datafield:egitmenadi]"},{"Id":2374373,"PropertyName":"title","Value":"Tarih"},{"Id":126638,"PropertyName":"value","Value":"[datafield:randevugun]"},{"Id":240608,"PropertyName":"value","Value":"."},{"Id":572179,"PropertyName":"value","Value":"[datafield:randevuay]"},{"Id":713462,"PropertyName":"value","Value":"."},{"Id":281815,"PropertyName":"value","Value":"[datafield:randevuyil]"},{"Id":567874,"PropertyName":"title","Value":"Saat"},{"Id":82941,"PropertyName":"value","Value":"[datafield:saat]"},{"Id":524318,"PropertyName":"title","Value":"Statü"},{"Id":486529,"PropertyName":"value","Value":"[datafield:statu]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.myschedule_8148818_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        MyContext: [],
	        RandevularimDanisan: [],
	        NAVIGATE: "",

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("myschedule", "master_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.MySchedulePageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("myschedule", "master_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("myschedule", "master_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.MySchedulePageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.MySchedulePageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    MySchedulePageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule/MySchedulePageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.RandevularimDanisan = result?.data.randevularimDanisan;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedulePageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	MySchedulePageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_8148818 = this.state.RandevularimDanisan;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		MyScheduleComponent_690363_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSelfService", "DavetiyeId", null);
						KuikaAppManager.addToPageInputVariables("RandevuSelfService", "RandevuId", Guid.create().toString());
						KuikaAppManager.addToPageInputVariables("RandevuSelfService", "DanisanKisiId", ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kisiId : null));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule", "RandevuSelfService", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "904223", null, "right", null, "80vw", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		MyScheduleComponent_322481_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, "myschedule_8148818_value", "randevuId"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "523033", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [904223, 523033] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.MySchedulePageInit();
		}

    }
}
