import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMailTest_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMailTest_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	SendMail: boolean;
	SendMail_dummy: boolean;


}

export class MailTest_ScreenBase extends React.PureComponent<IMailTest_ScreenProps, any> {

    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"40a74f76-a34e-4d9f-964b-690623c23b7b","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":570564,"PropertyName":"label","Value":"MAİL 1"},{"Id":880771,"PropertyName":"label","Value":"MAİL 2"},{"Id":878868,"PropertyName":"label","Value":"MAİL 3"},{"Id":210771,"PropertyName":"label","Value":"MAİL 4"},{"Id":112833,"PropertyName":"label","Value":"MAİL 5"},{"Id":189542,"PropertyName":"label","Value":"MAİL 6"},{"Id":401837,"PropertyName":"label","Value":"MAİL 7"},{"Id":466642,"PropertyName":"label","Value":"MAİL 8"},{"Id":446970,"PropertyName":"label","Value":"MAİL 9"},{"Id":405459,"PropertyName":"label","Value":"MAİL 10"},{"Id":531187,"PropertyName":"label","Value":"MAİL 11"},{"Id":996670,"PropertyName":"label","Value":"MAİL 12"},{"Id":207763,"PropertyName":"label","Value":"MAİL 13"},{"Id":64793,"PropertyName":"label","Value":"MAİL 14"},{"Id":974818,"PropertyName":"label","Value":"MAİL 15"},{"Id":19513,"PropertyName":"label","Value":"MAİL 16"},{"Id":808415,"PropertyName":"label","Value":"MAİL 17"},{"Id":845301,"PropertyName":"label","Value":"MAİL 18"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        SendMail: false,

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("mailtest", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("mailtest", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("mailtest", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      


    MailTestComponent_570564_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("2a24a374-5443-4bda-ad66-b8ae9a2e5cbc", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 1", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				baseUrl_0: ReactSystemFunctions.convertToTypeByName("http://kuika.com", "string"),
				username_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				verificationCode_0: ReactSystemFunctions.convertToTypeByName("123", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_570564_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_880771_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("77e1b837-fbfa-41b4-b5a3-baf6dcff9dfb", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 2", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				baseUrl_0: ReactSystemFunctions.convertToTypeByName("http://kuika.com", "string"),
				verificationCode_0: ReactSystemFunctions.convertToTypeByName("123", "string"),
				providerEmail_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_880771_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_878868_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("c873e5f6-20cc-4105-b307-5d8653065f15", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 3", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_878868_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_210771_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("d94fbc6d-954a-4222-b384-e866e7cae3c4", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 4", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_210771_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_112833_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("0d0e1234-fabf-43d2-a60f-79a9933c8fb7", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 5", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_112833_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_189542_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("912b1e84-8a2a-478a-86ce-4cffc534c1aa", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 6", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				baseUrl_0: ReactSystemFunctions.convertToTypeByName("http://kuika.com", "string"),
				username_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				verificationCode_0: ReactSystemFunctions.convertToTypeByName("123", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_189542_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_401837_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("97f9bf74-9dd0-46d6-8d03-05293ea9fe4c", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 7", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				provider_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				eposta_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.copm", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_401837_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_466642_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("79d10d08-1205-4c4b-9e63-72d333666848", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 8", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_466642_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_446970_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("3b2f7d1c-b5b0-4474-86a0-07e22cc27320", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 9", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				dan_0: ReactSystemFunctions.convertToTypeByName("dan", "string"),
				hizmeti_0: ReactSystemFunctions.convertToTypeByName("hizmeti", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_446970_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_405459_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("8fdab7a7-c255-49f0-a865-bfc3184a4371", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 10", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				dan_0: ReactSystemFunctions.convertToTypeByName("dan", "string"),
				hizmeti_0: ReactSystemFunctions.convertToTypeByName("hizm", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_405459_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_531187_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("838a98f0-5d2e-49fa-8f92-b9af7f26d2cc", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 11", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				provider_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_531187_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_996670_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("834752b9-500e-46bd-8f20-1d066e5c5bd3", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 12", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				dan_0: ReactSystemFunctions.convertToTypeByName("dan", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_996670_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_207763_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("c67a725b-58af-4855-b26a-a5729254d23a", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 13", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				dan_0: ReactSystemFunctions.convertToTypeByName("dan", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_207763_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_64793_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("9eaa3131-1ca0-4925-861a-40b9f05b397e", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 14", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				dan_0: ReactSystemFunctions.convertToTypeByName("dan", "string"),
				hizmeti_0: ReactSystemFunctions.convertToTypeByName("hizmeyi", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_64793_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_974818_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("9c6c0e43-2c55-4a33-9fb9-15194ac8c08c", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 15", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				provider_0: ReactSystemFunctions.convertToTypeByName("provider", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_974818_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_19513_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("a9a39075-ee4a-4245-a330-500e76d6833a", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 16", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string"),
				provider_0: ReactSystemFunctions.convertToTypeByName("provider", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_19513_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_808415_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("d81b7fed-38a9-4e0c-94f0-d5c33d5fcea6", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 17", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_808415_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    MailTestComponent_845301_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				templateId_0: ReactSystemFunctions.convertToTypeByName("cbc9ffbd-2c97-4be9-9e4a-0952aaba8c5a", "string"),
				receivers_0: ReactSystemFunctions.convertToTypeByName("stefano.tito@kuika.com", "string"),
				subject_0: ReactSystemFunctions.convertToTypeByName("Mail 18", "string"),
				sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				memap_0: ReactSystemFunctions.convertToTypeByName("MeMap2", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MailTest/MailTestComponent_845301_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
