import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IRandevuFromEgitmen2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IRandevuFromEgitmen2_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	SehirAll: any[];
	SehirAll_dummy: any[];
	DavetiyeById: any[];
	DavetiyeById_dummy: any[];
	RandevuById: any[];
	RandevuById_dummy: any[];
	HizmetById: any[];
	HizmetById_dummy: any[];
	EgitmenById: any[];
	EgitmenById_dummy: any[];
	RandevuMerkezler: any[];
	RandevuMerkezler_dummy: any[];
	ChangeEnabledOf: any;
	ChangeEnabledOf_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	isComp341303Enabled: 'enabled' | 'disabled';
	isComp477590Enabled: 'enabled' | 'disabled';
	isComp328086Enabled: 'enabled' | 'disabled';


}

export class RandevuFromEgitmen2_ScreenBase extends React.PureComponent<IRandevuFromEgitmen2_ScreenProps, any> {
	randevufromegitmen2_640758_value_kuikaSelectBoxRef: React.RefObject<any>;
	randevufromegitmen2_115554_value_kuikaFlexGridRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"cef2c98a-c054-4332-afa2-890b12b70b06","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Randevu"},{"Id":579589,"PropertyName":"value","Value":"Eğitmen"},{"Id":343251,"PropertyName":"value","Value":"Yaş"},{"Id":113358,"PropertyName":"value","Value":"Hizmet"},{"Id":821011,"PropertyName":"value","Value":"Süre"},{"Id":178000,"PropertyName":"value","Value":"dk"},{"Id":857648,"PropertyName":"value","Value":"Randevu almak istediğiniz merkezi seçiniz..."},{"Id":640758,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":115554,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":415392,"PropertyName":"value","Value":"[datafield:unvani]"},{"Id":738262,"PropertyName":"value","Value":"[datafield:acikadres]"},{"Id":341303,"PropertyName":"label","Value":"İLERİ"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.randevufromegitmen2_640758_value_kuikaSelectBoxRef = React.createRef();
		this.randevufromegitmen2_115554_value_kuikaFlexGridRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        SehirAll: [],
	        DavetiyeById: [],
	        RandevuById: [],
	        HizmetById: [],
	        EgitmenById: [],
	        RandevuMerkezler: [],
	        ChangeEnabledOf: "",
	        NAVIGATE: "",
	        isComp341303Enabled: 'enabled',
	        isComp477590Enabled: 'disabled',
	        isComp328086Enabled: 'disabled',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("randevufromegitmen2", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.RandevuFromEgitmen2PageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("randevufromegitmen2", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("randevufromegitmen2", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.RandevuFromEgitmen2PageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
randevufromegitmen2_420686_value: this.state.EgitmenById?.at?.(0)?.resim ?? undefined,
randevufromegitmen2_477590_value: this.state.EgitmenById?.at?.(0)?.rate ?? undefined,
randevufromegitmen2_218630_value: this.state.HizmetById?.at?.(0)?.image ?? undefined,
randevufromegitmen2_640758_value: this.state.MyContext?.at?.(0)?.sehirId ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    RandevuFromEgitmen2PageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			davetiyeId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid, "Guid"),
			randevuId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid, "Guid"),
			Id_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
			kisiId_5: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid, "Guid"),
			davetId_6: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid, "Guid"),
			seciliHizmetId_6: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
			seciliMerkezId_6: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
			seciliEgitmenId_6: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuFromEgitmen2/RandevuFromEgitmen2PageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		formVars.randevufromegitmen2_640758_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sehirId : null;
		formVars.randevufromegitmen2_640758_options = stateVars.SehirAll;
		stateVars.SehirAll = result?.data.sehirAll;
		
		formVars.randevufromegitmen2_640758_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sehirId : null;
		formVars.randevufromegitmen2_640758_options = stateVars.SehirAll;
		stateVars.DavetiyeById = result?.data.davetiyeById;
		stateVars.RandevuById = result?.data.randevuById;
		stateVars.HizmetById = result?.data.hizmetById;
		formVars.randevufromegitmen2_218630_value = ReactSystemFunctions.toString(this, stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0]?.image : null);
		formVars.randevufromegitmen2_341134_value = ReactSystemFunctions.toString(this, stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0]?.title : null);
		formVars.randevufromegitmen2_681119_value = ReactSystemFunctions.toString(this, stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0]?.aciklama : null);
		formVars.randevufromegitmen2_19129_value = ReactSystemFunctions.toString(this, stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0]?.sure : null);
		stateVars.EgitmenById = result?.data.egitmenById;
		formVars.randevufromegitmen2_420686_value = ReactSystemFunctions.toString(this, stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0]?.resim : null);
		formVars.randevufromegitmen2_41519_value = ReactSystemFunctions.toString(this, stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0]?.fullname : null);
		formVars.randevufromegitmen2_661792_value = ReactSystemFunctions.toString(this, stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0]?.unvan : null);
		formVars.randevufromegitmen2_285573_value = ReactSystemFunctions.toString(this, stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0]?.yas : null);
		stateVars.RandevuMerkezler = result?.data.randevuMerkezler;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2PageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	RandevuFromEgitmen2PageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2PageInit2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	RandevuFromEgitmen2PageInit2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, this.state.RandevuMerkezler?.length > 0 ? this.state.RandevuMerkezler[0]?.secilenMerkezId : null), null)) {
            
			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "enabled");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2PageInit3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2PageInit3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
	RandevuFromEgitmen2PageInit3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.randevufromegitmen2_420686_value = ReactSystemFunctions.toString(this, this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0]?.resim : null);

			formVars.randevufromegitmen2_41519_value = ReactSystemFunctions.toString(this, this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0]?.fullname : null);

			formVars.randevufromegitmen2_661792_value = ReactSystemFunctions.toString(this, this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0]?.unvan : null);

			formVars.randevufromegitmen2_285573_value = ReactSystemFunctions.toString(this, this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0]?.yas : null);

			formVars.randevufromegitmen2_477590_value = ReactSystemFunctions.value(this, this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0]?.rate : null);

			formVars.randevufromegitmen2_218630_value = ReactSystemFunctions.toString(this, this.state.HizmetById?.length > 0 ? this.state.HizmetById[0]?.image : null);

			formVars.randevufromegitmen2_341134_value = ReactSystemFunctions.toString(this, this.state.HizmetById?.length > 0 ? this.state.HizmetById[0]?.title : null);

			formVars.randevufromegitmen2_681119_value = ReactSystemFunctions.toString(this, this.state.HizmetById?.length > 0 ? this.state.HizmetById[0]?.aciklama : null);

			formVars.randevufromegitmen2_19129_value = ReactSystemFunctions.toString(this, this.state.HizmetById?.length > 0 ? this.state.HizmetById[0]?.sure : null);

			formVars.randevufromegitmen2_640758_value = ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sehirId : null);

			
			stateVars.dataSource_640758 = this.state.SehirAll;
			stateVars.dataSource_640758 = this.state.SehirAll;
			
			stateVars.dataSource_115554 = this.state.RandevuMerkezler;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }






		RandevuFromEgitmen2Component_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    RandevuFromEgitmen2Component_640758_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				davetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid, "Guid"),
				seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
				seciliMerkezId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				seciliEgitmenId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid, "Guid"),
				sehirId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "randevufromegitmen2_640758_value", "value", "SehirAll", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuFromEgitmen2/RandevuFromEgitmen2Component_640758_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuMerkezler = result?.data.randevuMerkezler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2Component_640758_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuFromEgitmen2Component_640758_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_115554 = this.state.RandevuMerkezler;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    RandevuFromEgitmen2Component_746730_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				davetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid, "Guid"),
				seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
				seciliMerkezId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "randevufromegitmen2_115554_value", "id"), "Guid"),
				seciliEgitmenId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid, "Guid"),
				sehirId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "randevufromegitmen2_640758_value", "value", "SehirAll", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuFromEgitmen2/RandevuFromEgitmen2Component_746730_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuMerkezler = result?.data.randevuMerkezler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2Component_746730_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuFromEgitmen2Component_746730_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2Component_746730_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
		RandevuFromEgitmen2Component_746730_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, this.state.RandevuMerkezler?.length > 0 ? this.state.RandevuMerkezler[0]?.secilenMerkezId : null), null)) {
            
				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "enabled");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2Component_746730_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuFromEgitmen2Component_746730_onClick3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuFromEgitmen2Component_746730_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_115554 = this.state.RandevuMerkezler;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






		RandevuFromEgitmen2Component_341303_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSlot", "RandevuId", this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid);
						KuikaAppManager.addToPageInputVariables("RandevuSlot", "EgitmenId", this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid);
						KuikaAppManager.addToPageInputVariables("RandevuSlot", "HizmetId", this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid);
						KuikaAppManager.addToPageInputVariables("RandevuSlot", "MerkezId", ReactSystemFunctions.value(this, this.state.RandevuMerkezler?.length > 0 ? this.state.RandevuMerkezler[0]?.secilenMerkezId : null));
						KuikaAppManager.addToPageInputVariables("RandevuSlot", "DavetiyeId", this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid);
						KuikaAppManager.addToPageInputVariables("RandevuSlot", "DanisanKisiId", this.props.screenInputs.DanisanKisiId ?? this.props.screenInputs.danisankisiid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.RandevuFromEgitmen2Component_341303_onClick1_, "RandevuFromEgitmen2", "RandevuSlot", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "287620", null, "right", null, "1198px", "100vh", false);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
		RandevuFromEgitmen2Component_341303_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		if (diId == 287620) {
			isErrorOccurred = await this.RandevuFromEgitmen2Component_341303_onClick1_();
            if (isErrorOccurred) return true;
		}

    }
}
