import * as React from "react";
import { Kirilim2List_ScreenBase } from "./kirilim2list-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer } from "../../redux/photo-take/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";
import {Master_Screen} from "../master/master";

import { Kirilim2Form_Screen } from "../kirilim2form/kirilim2form";
import { SinifinOgrencileri_Screen } from "../sinifinogrencileri/sinifinogrencileri";
import { TopluOlcumListSinif_Screen } from "../topluolcumlistsinif/topluolcumlistsinif";


declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class Kirilim2List_Screen extends Kirilim2List_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              <Master_Screen form={this.props.form} initialValues={{kirilim2list_416063_value: undefined
}} screenInputs={this.props.screenInputs} setMasterPageInitLoaded={this.setMasterPageInitLoaded} setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded} isMasterPageInitLoaded={this.state.isMasterPageInitLoaded} isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded} >
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                        
<div id="kirilim2list_body" style={{"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block","overflow":"auto","minHeight":"calc(100vh - 60px - 60px - 0px - 0px)","maxHeight":"calc(100vh - 60px - 60px - 0px - 0px)","height":"calc(100vh - 60px - 60px - 0px - 0px)"} as any}>
<KRow id="331958" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="44993" xs={15} sm={15} md={15} lg={15} xl={15} xxl={15} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="951682" direction="horizontal" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="923160" value={this.state.MyContext?.at?.(0)?.kirilim2Adi ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"18pt","color":"rgba(10, 33, 64, 1)","textTransform":"capitalize"} as any}></Label>

<Label id="363039" value={ReactSystemFunctions.translate(this.ml, 363039, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"18pt","color":"rgba(10, 33, 64, 1)","textTransform":"capitalize"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="775216" xs={9} sm={9} md={9} lg={9} xl={9} xxl={9} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="434546" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='kirilim2list_416063_value' >
<TextInput id="416063" onPressEnter={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_416063_onPressEnter()} } onBlur={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_416063_onBlur()} } placeholder={ReactSystemFunctions.translate(this.ml, 416063, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(255, 255, 255, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"maxWidth":"200px","alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>

<Button id="814413" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_814413_onClick()} } showCursorPointer visibility={this.state.isComp814413Visible} condition={(rowData) => (ReactSystemFunctions.isNotEqualTo(this.state.MyContext[0]?.rolename?.toString(), "Rol_Instructor"))} label={ReactSystemFunctions.translate(this.ml, 814413, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="720564" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="769800" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KTable id="399769" kuikaRef={this.kirilim2list_399769_value_kuikaTableRef} form={this.props.form} dataSource={this.state.Kirilim2Search} size="middle" bordered={true} showHeader={true} loading={false} nodatafoundmessage={ReactSystemFunctions.translate(this.ml, 399769, "nodatafoundmessage",this.defaultML)} sorter={false} pagination={false} striped={true} stripedColor="rgba(0, 190, 214, 0.1)" insertRowActive={false} searchable={false} filtering={false} transformedOnMobileResolution={false} tableWidthMode="fit" columnChooser={false} fixedHeader={false} pageSize={10} showSizeChanger={false} insertRowPosition="top" multiSelect={false} resizableColumns={false} editable={false} globalSearch={false} showNoDataFound={true} export={false} editableAlwaysActive={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KTableHeader id="500856" hideOnMobileResolution={false} textDirection="Default" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></KTableHeader>

<KTableRow id="489075" hoverFontColor="red" hoverBgColor="#F5F5F5" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"30px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KTableColumn id="2229937" title={ReactSystemFunctions.translate(this.ml, 2229937, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="3518158" value="[datafield:tanim]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="424506" title={ReactSystemFunctions.translate(this.ml, 424506, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"120px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="470013" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="41583" value="[datafield:ogrenciAdedi]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Icon id="575480" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_575480_onClick()} } showCursorPointer iconName="arrow_forward_ios" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</HorizontalStack>
</KTableColumn>

<KTableColumn id="444885" visibility={this.state.isComp444885Visible} title={ReactSystemFunctions.translate(this.ml, 444885, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"120px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="103155" direction="vertical" size={6} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="968629" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_968629_onClick()} } showCursorPointer value={ReactSystemFunctions.translate(this.ml, 968629, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Label id="741417" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_741417_onClick()} } showCursorPointer value={ReactSystemFunctions.translate(this.ml, 741417, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KTableColumn>

<KTableColumn id="837047" title={ReactSystemFunctions.translate(this.ml, 837047, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"120px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Button id="871482" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_871482_onClick()} } showCursorPointer label={ReactSystemFunctions.translate(this.ml, 871482, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"width":"80px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>
</KTableColumn>

<KTableColumn id="792509" visibility={this.state.isComp792509Visible} condition={(rowData) => ((ReactSystemFunctions.isNotEqualTo(this.state.MyContext[0]?.rolename?.toString(), "Rol_Instructor") || ReactSystemFunctions.isNotEqualTo(this.state.MyContext[0]?.kurumTipiKod?.toString(), "KT_School")))} title={ReactSystemFunctions.translate(this.ml, 792509, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"120px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="893506" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="860665" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_860665_onClick()} } showCursorPointer iconName="create" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>

<Icon id="505853" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_505853_onClick()} } showCursorPointer iconName="delete" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</HorizontalStack>
</KTableColumn>
</KTableRow>
</KTable>

<KPagination id="172599" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.Kirilim2ListComponent_172599_onChange()} } kuikaRef={this.kirilim2list_172599_value_kuikaPaginationRef} total={this.state.Kirilim2Count?.at?.(0)?.count ?? undefined} pageSize={10} defaultCurrent={1} current={1} defaultPageSize={10} disabled={false} hideOnSinglePage={true} showLessItems={false} showQuickJumper={false} showSizeChanger={false} options={[{'value':"Item 1"},{'value':"Item 2"}]} showFirstPageButton={true} showLastPageButton={true} showNumbers={false} showText={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KPagination>
</KCol>
</KRow>

<KDrawer id="256479" placement="right" onClose={() => { this.setState({NavVisible256479: false}, () => { this.callCallbackFunction(256479) })}} visible={this.state.NavVisible256479} width="450px" push={true} children={this.state.NavVisible256479 && <Kirilim2Form_Screen onClose={() => { this.setState({ NavVisible256479: false }, () => { this.callCallbackFunction(256479) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="486227" placement="right" onClose={() => { this.setState({NavVisible486227: false}, () => { this.callCallbackFunction(486227) })}} visible={this.state.NavVisible486227} width="450px" height="vh" push={true} children={this.state.NavVisible486227 && <Kirilim2Form_Screen onClose={() => { this.setState({ NavVisible486227: false }, () => { this.callCallbackFunction(486227) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="111009" placement="right" onClose={() => { this.setState({NavVisible111009: false}, () => { this.callCallbackFunction(111009) })}} visible={this.state.NavVisible111009} width="800px" height="100vh" push={true} children={this.state.NavVisible111009 && <SinifinOgrencileri_Screen onClose={() => { this.setState({ NavVisible111009: false }, () => { this.callCallbackFunction(111009) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="628113" placement="right" onClose={() => { this.setState({NavVisible628113: false}, () => { this.callCallbackFunction(628113) })}} visible={this.state.NavVisible628113} width="450px" height="100vh" push={true} children={this.state.NavVisible628113 && <TopluOlcumListSinif_Screen onClose={() => { this.setState({ NavVisible628113: false }, () => { this.callCallbackFunction(628113) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>
</div>

                    
                </Spin>
              </Master_Screen>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that))
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Kirilim2List_Screen))))));
export { tmp as Kirilim2List_Screen };
//export default tmp;
//export { tmp as Kirilim2List_Screen };

