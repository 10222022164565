
      var API = require("!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
      var domAPI = require("!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/style-loader/dist/runtime/styleDomAPI.js");
      var insertFn = require("!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/style-loader/dist/runtime/insertBySelector.js");
      var setAttributes = require("!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js");
      var insertStyleElement = require("!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/style-loader/dist/runtime/insertStyleElement.js");
      var styleTagTransformFn = require("!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/style-loader/dist/runtime/styleTagTransform.js");
      var content = require("!!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/css-loader/dist/cjs.js!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[12].use[2]!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/resolve-url-loader/index.js??ruleSet[1].rules[12].use[3]!../../../../../../../../../../../code-generation/platform/dependencies/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[12].use[4]!./badge.scss");
      
      content = content.__esModule ? content.default : content;

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



module.exports = content && content.locals || {};
