import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IResetPassword_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IResetPassword_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	ChangeEnabledOf: any;
	ChangeEnabledOf_dummy: any;
	IfThenElse: boolean;
	IfThenElse_dummy: boolean;
	PasswordReset: string;
	PasswordReset_dummy: string;
	goToSignInActivity: any;
	goToSignInActivity_dummy: any;
	isComp826965Enabled: 'enabled' | 'disabled';


}

export class ResetPassword_ScreenBase extends React.PureComponent<IResetPassword_ScreenProps, any> {

    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"709453a8-7716-4ba5-9656-e4b90c5e05a5","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":209555,"PropertyName":"value","Value":"Şifre Yenileme"},{"Id":515838,"PropertyName":"value","Value":"Lütfen yeni şifrenizi girin."},{"Id":565586,"PropertyName":"value","Value":"Şifre"},{"Id":646272,"PropertyName":"placeholder","Value":"******"},{"Id":53613,"PropertyName":"value","Value":"Şifre (Tekrar)"},{"Id":491266,"PropertyName":"placeholder","Value":"******"},{"Id":826965,"PropertyName":"label","Value":"Yenile"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        ChangeEnabledOf: "",
	        IfThenElse: false,
	        PasswordReset: "",
	        goToSignInActivity: "",
	        isComp826965Enabled: 'enabled',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "");
            return;
        }
        
        
        if (true){
            await this.ResetPasswordPageInit();
        }
        
        window.addEventListener("keydown", this.onKeyDown)
        
        KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "");
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown)
        
        
    }

    

        

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if(keyCode === 13){
      var element = document.getElementById("826965");
      if (element && element.click)
            {
                element.click();
            }
        }
    }

    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("resetpassword", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.ResetPasswordPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

	ResetPasswordPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp826965Enabled', "disabled");

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }


		ResetPasswordComponent_646272_onChange_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp826965Enabled', "enabled");

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		ResetPasswordComponent_646272_onChange_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp826965Enabled', "disabled");

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		ResetPasswordComponent_646272_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")), ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")))) {
            isErrorOccurred = await this.ResetPasswordComponent_646272_onChange_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.ResetPasswordComponent_646272_onChange_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		ResetPasswordComponent_491266_onChange_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp826965Enabled', "enabled");

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		ResetPasswordComponent_491266_onChange_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp826965Enabled', "disabled");

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }


		ResetPasswordComponent_491266_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")), ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", "")))) {
            isErrorOccurred = await this.ResetPasswordComponent_491266_onChange_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.ResetPasswordComponent_491266_onChange_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		ResetPasswordComponent_826965_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")), null),
			message: "This field is required.",
			formName: "resetpassword_491266_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
		validations.push({ 
			isValid: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")), ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "resetpassword_646272_value", "value", "", "", ""))),
			message: "The password confirmation does not match.",
			formName: "resetpassword_491266_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.PasswordReset = await ReactSystemFunctions.resetPassword(this,ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "resetpassword_491266_value", "value", "", "", "")), false, null);
				stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
