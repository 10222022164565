import { IUserInfoDto } from "../../models/dto/user-info.dto";

declare let window: any;

export interface ContextHocProp {
  context: KContext;
}

export const withContext = (Component: any) => {
  return (props: any) => {
    const context = new KContext();

    return <Component {...props} context={context} />;
  };
};

export class KContext {
  userInfo: IUserInfoDto;

  isWebComponentApp: boolean;

  constructor() {
    const { userInfo, kuika } = window;
    this.userInfo = userInfo;
    this.isWebComponentApp = kuika.isWebComponentApp;
  }
}
