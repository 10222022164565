import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IEkTestF_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IEkTestF_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	EkTestler: any[];
	EkTestler_dummy: any[];
	KisiSelectById: any[];
	KisiSelectById_dummy: any[];
	EkTestById: any[];
	EkTestById_dummy: any[];
	TestMeta: any[];
	TestMeta_dummy: any[];
	ChangeEnabledOf: any;
	ChangeEnabledOf_dummy: any;
	ReadFromLocalStorage: string;
	ReadFromLocalStorage_dummy: string;
	SetValueOf: any;
	SetValueOf_dummy: any;
	WriteToLocalStorage: any;
	WriteToLocalStorage_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	EkTestExecute: any[];
	EkTestExecute_dummy: any[];
	TriggerEvent: string;
	TriggerEvent_dummy: string;
	SaveRecord: number;
	SaveRecord_dummy: number;
	isComp521679Visible: 'visible' | 'hidden';
	isComp510803Visible: 'visible' | 'hidden';
	isComp671981Visible: 'visible' | 'hidden';
	isComp179819Visible: 'visible' | 'hidden';
	isComp488670Visible: 'visible' | 'hidden';
	isComp731109Visible: 'visible' | 'hidden';
	isComp561867Visible: 'visible' | 'hidden';
	isComp15252Visible: 'visible' | 'hidden';
	isComp932103Visible: 'visible' | 'hidden';
	isComp543557Visible: 'visible' | 'hidden';
	isComp491458Visible: 'visible' | 'hidden';
	isComp2509Enabled: 'enabled' | 'disabled';
	isComp944772Enabled: 'enabled' | 'disabled';
	isComp218416Enabled: 'enabled' | 'disabled';


}

export class EkTestF_ScreenBase extends React.PureComponent<IEkTestF_ScreenProps, any> {
	ektestf_486014_value_kuikaSelectBoxRef: React.RefObject<any>;
	ektestf_696401_value_kuikaDateRef: React.RefObject<any>;
    ml=[{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[{"Id":163663,"PropertyName":"value","Value":"Additional Test","TagName":"label4_value"},{"Id":8163186,"PropertyName":"value","Value":"Test Name","TagName":"lblRolename_value"},{"Id":486014,"PropertyName":"placeholder","Value":"Choose...","TagName":"selTest1_placeholder"},{"Id":101238,"PropertyName":"value","Value":"Test Date","TagName":"label2_value"},{"Id":696401,"PropertyName":"placeholder","Value":"Choose...","TagName":"dtCheckup_placeholder"},{"Id":2509,"PropertyName":"placeholder","Value":"Will be calculated","TagName":"numTest1Arasonuc1_placeholder"},{"Id":944772,"PropertyName":"placeholder","Value":"Will be calculated","TagName":"numTest1Arasonuc2_placeholder"},{"Id":218416,"PropertyName":"placeholder","Value":"Will be calculated","TagName":"numTest1Arasonuc3_placeholder"},{"Id":9544,"PropertyName":"label","Value":"SAVE","TagName":"btnSave_label"}]},{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","LanguagePhrases":[]},{"Id":"6c772c67-97ad-4b6a-afd7-cfe0ed3cd54e","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":163663,"PropertyName":"value","Value":"Ek Test"},{"Id":15126,"PropertyName":"value","Value":"Test Adı"},{"Id":8163186,"PropertyName":"value","Value":"Gelişmiş Arama"},{"Id":486014,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":101238,"PropertyName":"value","Value":"Test Tarihi"},{"Id":696401,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":2509,"PropertyName":"placeholder","Value":"Hesaplanacak"},{"Id":944772,"PropertyName":"placeholder","Value":"Hesaplanacak"},{"Id":218416,"PropertyName":"placeholder","Value":"Hesaplanacak"},{"Id":9544,"PropertyName":"label","Value":"KAYDET"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.ektestf_486014_value_kuikaSelectBoxRef = React.createRef();
		this.ektestf_696401_value_kuikaDateRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        EkTestler: [],
	        KisiSelectById: [],
	        EkTestById: [],
	        TestMeta: [],
	        ChangeEnabledOf: "",
	        ReadFromLocalStorage: "",
	        SetValueOf: "",
	        WriteToLocalStorage: "",
	        NAVIGATE: "",
	        EkTestExecute: [],
	        TriggerEvent: "",
	        SaveRecord: false,
	        isComp521679Visible: 'hidden',
	        isComp510803Visible: 'hidden',
	        isComp671981Visible: 'hidden',
	        isComp179819Visible: 'hidden',
	        isComp488670Visible: 'hidden',
	        isComp731109Visible: 'hidden',
	        isComp561867Visible: 'hidden',
	        isComp15252Visible: 'hidden',
	        isComp932103Visible: 'hidden',
	        isComp543557Visible: 'hidden',
	        isComp491458Visible: 'hidden',
	        isComp2509Enabled: 'enabled',
	        isComp944772Enabled: 'enabled',
	        isComp218416Enabled: 'enabled',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("ektestf", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.EkTestFPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("ektestf", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("ektestf", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.EkTestFPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
ektestf_486014_value: this.state.EkTestById?.at?.(0)?.testId ?? undefined,
ektestf_696401_value: this.state.EkTestById?.at?.(0)?.testTarihi ?? undefined,
ektestf_713180_value: this.state.EkTestById?.at?.(0)?.girdi1 ?? undefined,
ektestf_965121_value: this.state.EkTestById?.at?.(0)?.girdi2 ?? undefined,
ektestf_321884_value: this.state.EkTestById?.at?.(0)?.girdi3 ?? undefined,
ektestf_209103_value: this.state.EkTestById?.at?.(0)?.girdi4 ?? undefined,
ektestf_2509_value: this.state.EkTestById?.at?.(0)?.araSonuc1 ?? undefined,
ektestf_944772_value: this.state.EkTestById?.at?.(0)?.araSonuc2 ?? undefined,
ektestf_218416_value: this.state.EkTestById?.at?.(0)?.araSonuc3 ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    EkTestFPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			kisiId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid, "Guid"),
			Id_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EkTEstId ?? this.props.screenInputs.ektestid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "EkTestF/EkTestFPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.EkTestler = result?.data.ekTestler;
		
		formVars.ektestf_486014_value = stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.testId : null;
		formVars.ektestf_486014_options = stateVars.EkTestler;
		stateVars.KisiSelectById = result?.data.kisiSelectById;
		stateVars.EkTestById = result?.data.ekTestById;
		formVars.ektestf_486014_value = stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.testId : null;
		formVars.ektestf_486014_options = stateVars.EkTestler;
		formVars.ektestf_696401_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.testTarihi : null);
		formVars.ektestf_713180_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.girdi1 : null);
		formVars.ektestf_713180_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi1Aciklama : null);
		formVars.ektestf_965121_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.girdi2 : null);
		formVars.ektestf_965121_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi2Aciklama : null);
		formVars.ektestf_321884_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.girdi3 : null);
		formVars.ektestf_321884_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi3Aciklama : null);
		formVars.ektestf_209103_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.girdi4 : null);
		formVars.ektestf_209103_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi4Aciklama : null);
		formVars.ektestf_2509_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.araSonuc1 : null);
		formVars.ektestf_944772_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.araSonuc2 : null);
		formVars.ektestf_218416_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.araSonuc3 : null);
		stateVars.TestMeta = result?.data.testMeta;
		formVars.ektestf_796952_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre1Adi : null);
		formVars.ektestf_713180_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.girdi1 : null);
		formVars.ektestf_713180_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi1Aciklama : null);
		formVars.ektestf_11697_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre2Adi : null);
		formVars.ektestf_965121_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.girdi2 : null);
		formVars.ektestf_965121_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi2Aciklama : null);
		formVars.ektestf_364015_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre3Adi : null);
		formVars.ektestf_321884_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.girdi3 : null);
		formVars.ektestf_321884_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi3Aciklama : null);
		formVars.ektestf_976632_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre4Adi : null);
		formVars.ektestf_209103_value = ReactSystemFunctions.value(this, stateVars.EkTestById?.length > 0 ? stateVars.EkTestById[0]?.girdi4 : null);
		formVars.ektestf_209103_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi4Aciklama : null);
		formVars.ektestf_995102_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc1Adi : null);
		formVars.ektestf_681375_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc2Adi : null);
		formVars.ektestf_534470_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc3Adi : null);
		stateVars.isComp521679Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre1Adi : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp510803Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi1Aciklama : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp671981Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre2Adi : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp179819Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi2Aciklama : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp488670Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre3Adi : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp731109Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi3Aciklama : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp561867Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre4Adi : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp15252Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi4Aciklama : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp932103Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc1Adi : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp543557Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc2Adi : null), null)) === true ? "visible" : "hidden")
		stateVars.isComp491458Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc3Adi : null), null)) === true ? "visible" : "hidden")
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.EkTestFPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	EkTestFPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp2509Enabled', "disabled");
			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp944772Enabled', "disabled");
			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp218416Enabled', "disabled");
			stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("testId");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.EkTestFPageInit2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	EkTestFPageInit2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage), null)) {
            
			stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ektestf_486014_value", ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage), null, "Id");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.EkTestFPageInit3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.EkTestFPageInit3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
	EkTestFPageInit3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.ektestf_486014_value = ReactSystemFunctions.toString(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.testId : null);

			
			stateVars.dataSource_486014 = this.state.EkTestler;
			stateVars.dataSource_486014 = this.state.EkTestler;
			formVars.ektestf_696401_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.testTarihi : null);

			stateVars.isComp521679Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre1Adi : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_796952_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre1Adi : null);

			formVars.ektestf_510803_title = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi1Aciklama : null);

			stateVars.isComp510803Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi1Aciklama : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_713180_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.girdi1 : null);

			formVars.ektestf_713180_placeholder = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi1Aciklama : null);

			stateVars.isComp671981Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre2Adi : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_11697_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre2Adi : null);

			formVars.ektestf_179819_title = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi2Aciklama : null);

			stateVars.isComp179819Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi2Aciklama : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_965121_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.girdi2 : null);

			formVars.ektestf_965121_placeholder = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi2Aciklama : null);

			stateVars.isComp488670Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre3Adi : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_364015_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre3Adi : null);

			formVars.ektestf_731109_title = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi3Aciklama : null);

			stateVars.isComp731109Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi3Aciklama : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_321884_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.girdi3 : null);

			formVars.ektestf_321884_placeholder = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi3Aciklama : null);

			stateVars.isComp561867Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre4Adi : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_976632_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre4Adi : null);

			formVars.ektestf_15252_title = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi4Aciklama : null);

			stateVars.isComp15252Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi4Aciklama : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_209103_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.girdi4 : null);

			formVars.ektestf_209103_placeholder = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi4Aciklama : null);

			stateVars.isComp932103Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc1Adi : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_995102_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc1Adi : null);

			formVars.ektestf_2509_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.araSonuc1 : null);

			stateVars.isComp543557Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc2Adi : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_681375_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc2Adi : null);

			formVars.ektestf_944772_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.araSonuc2 : null);

			stateVars.isComp491458Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc3Adi : null), null)) === true ? "visible" : "hidden")
			formVars.ektestf_534470_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc3Adi : null);

			formVars.ektestf_218416_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.araSonuc3 : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }






		EkTestFComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		EkTestFComponent_8163186_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage("testId",null);
					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.EkTestFComponent_8163186_onClick1_, "EkTestF", "TestFilter", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "982109", null, "right", null, "580px", "100vh", false);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
		EkTestFComponent_8163186_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("testId");
				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ektestf_486014_value", ReactSystemFunctions.toString(this, stateVars.ReadFromLocalStorage), null, "Id");

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }



    EkTestFComponent_486014_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				test1Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_486014_value", "value", "EkTestler", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "EkTestF/EkTestFComponent_486014_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TestMeta = result?.data.testMeta;
			formVars.ektestf_796952_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre1Adi : null);
			formVars.ektestf_713180_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.girdi1 : null);
			formVars.ektestf_713180_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi1Aciklama : null);
			formVars.ektestf_11697_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre2Adi : null);
			formVars.ektestf_965121_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.girdi2 : null);
			formVars.ektestf_965121_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi2Aciklama : null);
			formVars.ektestf_364015_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre3Adi : null);
			formVars.ektestf_321884_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.girdi3 : null);
			formVars.ektestf_321884_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi3Aciklama : null);
			formVars.ektestf_976632_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre4Adi : null);
			formVars.ektestf_209103_value = ReactSystemFunctions.value(this, this.state.EkTestById?.length > 0 ? this.state.EkTestById[0]?.girdi4 : null);
			formVars.ektestf_209103_placeholder = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi4Aciklama : null);
			formVars.ektestf_995102_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc1Adi : null);
			formVars.ektestf_681375_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc2Adi : null);
			formVars.ektestf_534470_value = ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc3Adi : null);
			stateVars.isComp521679Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre1Adi : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp510803Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi1Aciklama : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp671981Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre2Adi : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp179819Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi2Aciklama : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp488670Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre3Adi : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp731109Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi3Aciklama : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp561867Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Parametre4Adi : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp15252Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1Girdi4Aciklama : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp932103Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc1Adi : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp543557Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc2Adi : null), null)) === true ? "visible" : "hidden")
			stateVars.isComp491458Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, stateVars.TestMeta?.length > 0 ? stateVars.TestMeta[0]?.test1AraSonuc3Adi : null), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.EkTestFComponent_486014_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		EkTestFComponent_486014_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				formVars.ektestf_796952_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre1Adi : null);

				formVars.ektestf_510803_title = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi1Aciklama : null);

				formVars.ektestf_713180_placeholder = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi1Aciklama : null);

				formVars.ektestf_11697_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre2Adi : null);

				formVars.ektestf_179819_title = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi2Aciklama : null);

				formVars.ektestf_965121_placeholder = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi2Aciklama : null);

				formVars.ektestf_364015_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre3Adi : null);

				formVars.ektestf_731109_title = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi3Aciklama : null);

				formVars.ektestf_321884_placeholder = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi3Aciklama : null);

				formVars.ektestf_976632_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Parametre4Adi : null);

				formVars.ektestf_15252_title = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi4Aciklama : null);

				formVars.ektestf_209103_placeholder = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1Girdi4Aciklama : null);

				formVars.ektestf_995102_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc1Adi : null);

				formVars.ektestf_681375_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc2Adi : null);

				formVars.ektestf_534470_value = ReactSystemFunctions.toString(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.test1AraSonuc3Adi : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    EkTestFComponent_713180_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_713180_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_713180_value",
				condition: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.isRequiredTest1Param1 : null), 1)
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_965121_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_965121_value",
				condition: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.isRequiredTest1Param2 : null), 1)
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_321884_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_321884_value",
				condition: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.isRequiredTest1Param3 : null), 1)
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_209103_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_209103_value",
				condition: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.isRequiredTest1Param4 : null), 1)
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				testId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_486014_value", "value", "EkTestler", "id", "id")), "Guid"),
				yas_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0]?.yas : null), "number"),
				boy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0]?.boy : null), "number"),
				cinsiyet_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0]?.cinsiyet : null), "string"),
				vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0]?.vucutAgirligi : null), "number"),
				parametre1_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_713180_value", "value", "", "", "")), "number"),
				parametre2_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_965121_value", "value", "", "", "")), "number"),
				parametre3_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_321884_value", "value", "", "", "")), "number"),
				parametre4_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_209103_value", "value", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "EkTestF/EkTestFComponent_713180_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.EkTestExecute = result?.data.ekTestExecute;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.EkTestFComponent_713180_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		EkTestFComponent_713180_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ektestf_2509_value", ReactSystemFunctions.value(this, this.state.EkTestExecute?.length > 0 ? this.state.EkTestExecute[0]?.araSonuc1 : null), null);
				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ektestf_944772_value", ReactSystemFunctions.value(this, this.state.EkTestExecute?.length > 0 ? this.state.EkTestExecute[0]?.araSonuc2 : null), null);
				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ektestf_218416_value", ReactSystemFunctions.value(this, this.state.EkTestExecute?.length > 0 ? this.state.EkTestExecute[0]?.araSonuc3 : null), null);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




		EkTestFComponent_965121_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				if(await this.EkTestFComponent_713180_onChange()) return true;
				

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		EkTestFComponent_321884_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				if(await this.EkTestFComponent_713180_onChange()) return true;
				

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		EkTestFComponent_209103_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				if(await this.EkTestFComponent_713180_onChange()) return true;
				

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    EkTestFComponent_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_696401_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_696401_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_209103_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_209103_value",
				condition: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.isRequiredTest1Param4 : null), 1)
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_321884_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_321884_value",
				condition: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.isRequiredTest1Param3 : null), 1)
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_965121_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_965121_value",
				condition: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.isRequiredTest1Param2 : null), 1)
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_713180_value", "value", "", "", "")), null),
				message: "*",
				formName: "ektestf_713180_value",
				condition: ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.TestMeta?.length > 0 ? this.state.TestMeta[0]?.isRequiredTest1Param1 : null), 1)
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EkTEstId ?? this.props.screenInputs.ektestid, "Guid"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid, "Guid"),
				TestId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_486014_value", "value", "EkTestler", "id", "id")), "Guid"),
				TestTarihi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_696401_value", "value", "", "", "")), "Date"),
				Girdi4_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_209103_value", "value", "", "", "")), "number"),
				Girdi3_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_321884_value", "value", "", "", "")), "number"),
				Girdi2_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_965121_value", "value", "", "", "")), "number"),
				Girdi1_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_713180_value", "value", "", "", "")), "number"),
				AraSonuc3_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_218416_value", "value", "", "", "")), "number"),
				AraSonuc1_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_2509_value", "value", "", "", "")), "number"),
				AraSonuc2_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "ektestf_944772_value", "value", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "EkTestF/EkTestFComponent_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.EkTestFComponent_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		EkTestFComponent_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		if (diId == 982109) {
			isErrorOccurred = await this.EkTestFComponent_8163186_onClick1_();
            if (isErrorOccurred) return true;
		}

    }
}
