import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IStatisticsDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IStatisticsDetay_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	Kirilim2SelectById: any[];
	Kirilim2SelectById_dummy: any[];
	StatisticsBilesenDetay: any[];
	StatisticsBilesenDetay_dummy: any[];


}

export class StatisticsDetay_ScreenBase extends React.PureComponent<IStatisticsDetay_ScreenProps, any> {
	statisticsdetay_906672_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"8a61fe55-3fbc-47a2-855a-06d0a1c3f1d8","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":768210,"PropertyName":"value","Value":"Sınıf İstatistikleri"},{"Id":487947,"PropertyName":"value","Value":":"},{"Id":78658,"PropertyName":"value","Value":"Bileşen"},{"Id":373699,"PropertyName":"value","Value":":"},{"Id":738733,"PropertyName":"title","Value":"<25"},{"Id":778811,"PropertyName":"value","Value":"[datafield:u25ogrenciadi]"},{"Id":100724,"PropertyName":"title","Value":"25 - 50"},{"Id":532373,"PropertyName":"value","Value":"[datafield:u51ogrenciadi]"},{"Id":580726,"PropertyName":"title","Value":"50 - 75"},{"Id":201367,"PropertyName":"value","Value":"[datafield:u76ogrenciadi]"},{"Id":556344,"PropertyName":"title","Value":"75 - 100"},{"Id":682010,"PropertyName":"value","Value":"[datafield:u101ogrenciadi]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.statisticsdetay_906672_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        Kirilim2SelectById: [],
	        StatisticsBilesenDetay: [],

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("statisticsdetay", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.StatisticsDetayPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("statisticsdetay", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("statisticsdetay", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.StatisticsDetayPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    StatisticsDetayPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id, "Guid"),
			sinifId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id, "Guid"),
			sira_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.bilesensira ?? this.props.screenInputs.bilesensira, "number"),
			protokolID_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "StatisticsDetay/StatisticsDetayPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		formVars.statisticsdetay_84281_value = ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.kirilim2Adi : null);
		stateVars.Kirilim2SelectById = result?.data.kirilim2SelectById;
		formVars.statisticsdetay_959614_value = ReactSystemFunctions.toString(this, stateVars.Kirilim2SelectById?.length > 0 ? stateVars.Kirilim2SelectById[0]?.tanim : null);
		stateVars.StatisticsBilesenDetay = result?.data.statisticsBilesenDetay;
		
		formVars.statisticsdetay_760087_value = ReactSystemFunctions.toString(this, stateVars.StatisticsBilesenDetay?.length > 0 ? stateVars.StatisticsBilesenDetay[0]?.tanim : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.StatisticsDetayPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	StatisticsDetayPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.statisticsdetay_84281_value = ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kirilim2Adi : null);

			formVars.statisticsdetay_959614_value = ReactSystemFunctions.toString(this, this.state.Kirilim2SelectById?.length > 0 ? this.state.Kirilim2SelectById[0]?.tanim : null);

			formVars.statisticsdetay_760087_value = ReactSystemFunctions.toString(this, this.state.StatisticsBilesenDetay?.length > 0 ? this.state.StatisticsBilesenDetay[0]?.tanim : null);

			
			stateVars.dataSource_906672 = this.state.StatisticsBilesenDetay;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		StatisticsDetayComponent_629109_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
