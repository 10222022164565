import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMaster_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMaster_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	MyContext: any[];
	MyContext_dummy: any[];
	KurumSelectById: any[];
	KurumSelectById_dummy: any[];
	LeftMenu: any[];
	LeftMenu_dummy: any[];
	MasterScrYetki: any[];
	MasterScrYetki_dummy: any[];
	ReadFromLocalStorage: string;
	ReadFromLocalStorage_dummy: string;
	SetValueOf: any;
	SetValueOf_dummy: any;
	WriteToLocalStorage: any;
	WriteToLocalStorage_dummy: any;
	OpenInBrowser: any;
	OpenInBrowser_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	Logout: boolean;
	Logout_dummy: boolean;
	goToSignInActivity: any;
	goToSignInActivity_dummy: any;
	isComp99793Visible: 'visible' | 'hidden';


}

export class Master_ScreenBase extends React.PureComponent<IMaster_ScreenProps, any> {

    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"6fd307f3-fd9e-4ee9-9c48-c2cb0b3a87ec","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":985455,"PropertyName":"label","Value":"Menu Item 1"},{"Id":383613,"PropertyName":"value","Value":"Kaynaklar"},{"Id":179698,"PropertyName":"value","Value":"LOGOUT"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        MyContext: [],
	        KurumSelectById: [],
	        LeftMenu: [],
	        MasterScrYetki: [],
	        ReadFromLocalStorage: "",
	        SetValueOf: "",
	        WriteToLocalStorage: "",
	        OpenInBrowser: "",
	        NAVIGATE: "",
	        Logout: false,
	        goToSignInActivity: "",
	        isComp99793Visible: 'hidden',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("master", "");
            return;
        }
        
        
        if (true){
            await this.MasterPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("master", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("master", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.MasterPageInit();
		}

        		if (prevProps.isMasterPageInitLoaded !== this.props.isMasterPageInitLoaded && this.props.isMasterPageInitLoaded === true) { }

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
master_189293_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined,
master_920284_value: { collapsed: (false) }
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    MasterPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Master/MasterPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		formVars.master_719694_value = ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.kurumAdi : null);
		formVars.master_16000_value = ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolenameDesc : null);
		stateVars.KurumSelectById = result?.data.kurumSelectById;
		formVars.master_189293_value = ReactSystemFunctions.toString(this, stateVars.KurumSelectById?.length > 0 ? stateVars.KurumSelectById[0]?.logo : null);
		stateVars.LeftMenu = result?.data.leftMenu;
		
		stateVars.MasterScrYetki = result?.data.masterScrYetki;
		stateVars.isComp99793Visible = ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.MasterScrYetki?.length > 0 ? stateVars.MasterScrYetki[0]?.kaynaklarYetki : null), "1")) === true ? "visible" : "hidden")
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MasterPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	MasterPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("menuKey");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MasterPageInit2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	MasterPageInit2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage), null)) {
            
			stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "master_920284_value", ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage), "selectedKey");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MasterPageInit3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MasterPageInit3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
	MasterPageInit3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.master_189293_value = ReactSystemFunctions.toString(this, this.state.KurumSelectById?.length > 0 ? this.state.KurumSelectById[0]?.logo : null);

			
			stateVars.dataSource_920284 = this.state.LeftMenu;
			stateVars.dataSource_920284 = this.state.LeftMenu;
			stateVars.isComp99793Visible = ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.MasterScrYetki?.length > 0 ? this.state.MasterScrYetki[0]?.kaynaklarYetki : null), "1")) === true ? "visible" : "hidden")
			formVars.master_719694_value = ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kurumAdi : null);

			formVars.master_16000_value = ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolenameDesc : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.props.setMasterPageInitLoaded(true);
            return isErrorOccurred;
        }






		MasterComponent_920284_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage("menuKey",ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "master_920284_value", "selectedKey", "LeftMenu", "", "")));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		MasterComponent_383613_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("http://yeninesilfitnessegitimi.com/class/school_fitness_check-up", "newtab");

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		MasterComponent_862119_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage("menuKey","Menu_GostergePaneli");
					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Master", "Settings", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "762089", null, "left", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		MasterComponent_77603_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.Logout = await ReactSystemFunctions.logout();
				stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		MasterComponent_179698_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.Logout = await ReactSystemFunctions.logout();
				stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [762089] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.MasterPageInit();
		}

    }
}
