import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IBilesenAltBilesenleriList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IBilesenAltBilesenleriList_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	BilesenAltBilesenleri: any[];
	BilesenAltBilesenleri_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;
	DeleteRecord: number;
	DeleteRecord_dummy: number;


}

export class BilesenAltBilesenleriList_ScreenBase extends React.PureComponent<IBilesenAltBilesenleriList_ScreenProps, any> {
	bilesenaltbilesenlerilist_399769_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[{"Id":923160,"PropertyName":"value","Value":"SUB COMPONENTS","TagName":"lblHead_value"},{"Id":814413,"PropertyName":"label","Value":"NEW","TagName":"btnNew_label"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"no data found","TagName":"tblFitnessbileseniolcumsekli_nodatafoundmessage"},{"Id":8107049,"PropertyName":"title","Value":"Test Name","TagName":"clmTanim_title"},{"Id":8143247,"PropertyName":"value","Value":"[datafield:description]","TagName":"lblTanim_value"},{"Id":241476,"PropertyName":"title","Value":"Tests","TagName":"TableColumn1_title"},{"Id":137753,"PropertyName":"value","Value":"[datafield:numberoftest]","TagName":"Label1_0_value"}]},{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","LanguagePhrases":[]},{"Id":"b5bd249c-f66d-4ac2-b064-696296a9847e","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":923160,"PropertyName":"value","Value":"ALT BİLEŞENLER"},{"Id":814413,"PropertyName":"label","Value":"YENİ"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"Veri Bulunamadı"},{"Id":8107049,"PropertyName":"title","Value":"Test Adı"},{"Id":8143247,"PropertyName":"value","Value":"[datafield:tanim]"},{"Id":241476,"PropertyName":"title","Value":"Testler"},{"Id":137753,"PropertyName":"value","Value":"[datafield:testadet]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.bilesenaltbilesenlerilist_399769_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        NAVIGATE: "",
	        BilesenAltBilesenleri: [],
	        SaveRecord: false,
	        DeleteRecord: false,

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("bilesenaltbilesenlerilist", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.BilesenAltBilesenleriListPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("bilesenaltbilesenlerilist", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("bilesenaltbilesenlerilist", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.BilesenAltBilesenleriListPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    BilesenAltBilesenleriListPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			BilesenId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.BilesenId ?? this.props.screenInputs.bilesenid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "BilesenAltBilesenleriList/BilesenAltBilesenleriListPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.BilesenAltBilesenleri = result?.data.bilesenAltBilesenleri;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.BilesenAltBilesenleriListPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	BilesenAltBilesenleriListPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_399769 = this.state.BilesenAltBilesenleri;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		BilesenAltBilesenleriListComponent_814413_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("AltBilesenForm", "BilesenId", this.props.screenInputs.BilesenId ?? this.props.screenInputs.bilesenid);
						KuikaAppManager.addToPageInputVariables("AltBilesenForm", "AltBilesenId", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "BilesenAltBilesenleriList", "AltBilesenForm", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "486227", null, "right", null, "450px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		BilesenAltBilesenleriListComponent_532088_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("AltBilesenTestler", "AltBilesenId", ReactSystemFunctions.value(this, "bilesenaltbilesenlerilist_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "BilesenAltBilesenleriList", "AltBilesenTestler", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "857148", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		BilesenAltBilesenleriListComponent_860665_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("AltBilesenForm", "BilesenId", this.props.screenInputs.BilesenId ?? this.props.screenInputs.bilesenid);
						KuikaAppManager.addToPageInputVariables("AltBilesenForm", "AltBilesenId", ReactSystemFunctions.value(this, "bilesenaltbilesenlerilist_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "BilesenAltBilesenleriList", "AltBilesenForm", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "875276", null, "right", null, "450px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    BilesenAltBilesenleriListComponent_505853_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				AltBilesenId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				AltBilesenId_0_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "bilesenaltbilesenlerilist_399769_value", "id"), "Guid"),
				Id_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "bilesenaltbilesenlerilist_399769_value", "id"), "Guid"),
				BilesenId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.BilesenId ?? this.props.screenInputs.bilesenid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "BilesenAltBilesenleriList/BilesenAltBilesenleriListComponent_505853_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			stateVars.DeleteRecord = result?.data.deleteRecord_1;
			stateVars.BilesenAltBilesenleri = result?.data.bilesenAltBilesenleri;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.BilesenAltBilesenleriListComponent_505853_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		BilesenAltBilesenleriListComponent_505853_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.BilesenAltBilesenleri;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [486227, 857148, 875276] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.BilesenAltBilesenleriListPageInit();
		}

    }
}
