import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ITopluOlcumListSinif_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ITopluOlcumListSinif_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	TopluOlcumListbyKirilim2: any[];
	TopluOlcumListbyKirilim2_dummy: any[];
	Kirilim2SelectById: any[];
	Kirilim2SelectById_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	DeleteTopluOlcum: number;
	DeleteTopluOlcum_dummy: number;
	isComp972444Visible: 'visible' | 'hidden';
	isComp316987Enabled: 'enabled' | 'disabled';


}

export class TopluOlcumListSinif_ScreenBase extends React.PureComponent<ITopluOlcumListSinif_ScreenProps, any> {
	topluolcumlistsinif_619549_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"adbd86d2-9063-44ae-8e9f-824311037081","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":711715,"PropertyName":"value","Value":"Check-Up Listesi"},{"Id":292678,"PropertyName":"value","Value":"Sınıf :"},{"Id":316987,"PropertyName":"placeholder","Value":"Type here..."},{"Id":195526,"PropertyName":"label","Value":"Yeni Ölçüm"},{"Id":619549,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":507812,"PropertyName":"title","Value":"Check-Up Tarihi"},{"Id":460714,"PropertyName":"value","Value":"[datafield:tarih]"},{"Id":981412,"PropertyName":"title","Value":"Değerlendirilen Öğrenci"},{"Id":564860,"PropertyName":"value","Value":"[datafield:adet]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.topluolcumlistsinif_619549_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        TopluOlcumListbyKirilim2: [],
	        Kirilim2SelectById: [],
	        NAVIGATE: "",
	        DeleteTopluOlcum: 0,
	        isComp972444Visible: 'hidden',
	        isComp316987Enabled: 'disabled',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("topluolcumlistsinif", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.TopluOlcumListSinifPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("topluolcumlistsinif", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("topluolcumlistsinif", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.TopluOlcumListSinifPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
topluolcumlistsinif_316987_value: this.state.Kirilim2SelectById?.at?.(0)?.tanim ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    TopluOlcumListSinifPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Kirilim2Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id, "Guid"),
			CheckupDonemiId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid, "Guid"),
			Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "TopluOlcumListSinif/TopluOlcumListSinifPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.TopluOlcumListbyKirilim2 = result?.data.topluOlcumListbyKirilim2;
		
		stateVars.Kirilim2SelectById = result?.data.kirilim2SelectById;
		formVars.topluolcumlistsinif_316987_value = ReactSystemFunctions.toString(this, stateVars.Kirilim2SelectById?.length > 0 ? stateVars.Kirilim2SelectById[0]?.tanim : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.TopluOlcumListSinifPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	TopluOlcumListSinifPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.topluolcumlistsinif_316987_value = ReactSystemFunctions.toString(this, this.state.Kirilim2SelectById?.length > 0 ? this.state.Kirilim2SelectById[0]?.tanim : null);

			
			stateVars.dataSource_619549 = this.state.TopluOlcumListbyKirilim2;
			stateVars.isComp972444Visible = ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "topluolcumlistsinif_619549_value", "silinebilirMi"), "1")) === true ? "visible" : "hidden")

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		TopluOlcumListSinifComponent_565790_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		TopluOlcumListSinifComponent_195526_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "Kirilim2ID", this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id);
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "Kirilim1ID", this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id);
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "KurumId", this.props.screenInputs.KurumId ?? this.props.screenInputs.kurumid);
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "CheckupDonemiID", this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid);
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "TopluOlcumId", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "TopluOlcumListSinif", "TopluCheckupForm", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		TopluOlcumListSinifComponent_843933_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "Kirilim2ID", this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id);
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "Kirilim1ID", this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id);
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "KurumId", this.props.screenInputs.KurumId ?? this.props.screenInputs.kurumid);
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "CheckupDonemiID", this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid);
						KuikaAppManager.addToPageInputVariables("TopluCheckupForm", "TopluOlcumId", ReactSystemFunctions.value(this, "topluolcumlistsinif_619549_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.TopluOlcumListSinifComponent_843933_onClick1_, "TopluOlcumListSinif", "TopluCheckupForm", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
    TopluOlcumListSinifComponent_843933_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id, "Guid"),
				CheckupDonemiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid, "Guid"),
				rolename_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null), "string")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "TopluOlcumListSinif/TopluOlcumListSinifComponent_843933_onClick1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TopluOlcumListbyKirilim2 = result?.data.topluOlcumListbyKirilim2;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.TopluOlcumListSinifComponent_843933_onClick2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		TopluOlcumListSinifComponent_843933_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_619549 = this.state.TopluOlcumListbyKirilim2;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





    TopluOlcumListSinifComponent_972444_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				topluolcumid_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "topluolcumlistsinif_619549_value", "id"), "Guid"),
				Kirilim2Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id, "Guid"),
				CheckupDonemiId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid, "Guid"),
				rolename_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "TopluOlcumListSinif/TopluOlcumListSinifComponent_972444_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.DeleteTopluOlcum = result?.data.deleteTopluOlcum;
			stateVars.TopluOlcumListbyKirilim2 = result?.data.topluOlcumListbyKirilim2;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.TopluOlcumListSinifComponent_972444_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		TopluOlcumListSinifComponent_972444_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_619549 = this.state.TopluOlcumListbyKirilim2;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [, ] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.TopluOlcumListSinifPageInit();
		}

    }
}
