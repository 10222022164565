import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ICheckupPasta_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ICheckupPasta_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	CheckupDetaylari: any[];
	CheckupDetaylari_dummy: any[];
	ChartLevels: any[];
	ChartLevels_dummy: any[];
	CheckupById: any[];
	CheckupById_dummy: any[];


}

export class CheckupPasta_ScreenBase extends React.PureComponent<ICheckupPasta_ScreenProps, any> {

    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"2db0b781-e413-445f-825d-4e150a076fd4","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":391231,"PropertyName":"value","Value":"label"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        CheckupDetaylari: [],
	        ChartLevels: [],
	        CheckupById: [],

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("checkuppasta", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.CheckupPastaPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("checkuppasta", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("checkuppasta", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.CheckupPastaPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    CheckupPastaPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			checkupId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid, "Guid"),
			olcumId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid, "Guid"),
			Id_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "CheckupPasta/CheckupPastaPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.CheckupDetaylari = result?.data.checkupDetaylari;
		stateVars.ChartLevels = result?.data.chartLevels;
		stateVars.CheckupById = result?.data.checkupById;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.CheckupPastaPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	CheckupPastaPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			
			stateVars.dataSource_761662 = this.state.CheckupDetaylari;
			stateVars.dataSource_761662 = this.state.CheckupDetaylari;
			formVars.checkuppasta_155443_text = ReactSystemFunctions.toString(this, this.state.CheckupById?.length > 0 ? this.state.CheckupById[0]?.fitSkor : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		CheckupPastaComponent_436910_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
