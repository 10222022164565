import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ISiniflandirmaSeviyeList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ISiniflandirmaSeviyeList_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	SiniflandirmaDetaySearch: any[];
	SiniflandirmaDetaySearch_dummy: any[];
	SiniflandirmaDetayDeleteById: number;
	SiniflandirmaDetayDeleteById_dummy: number;


}

export class SiniflandirmaSeviyeList_ScreenBase extends React.PureComponent<ISiniflandirmaSeviyeList_ScreenProps, any> {
	siniflandirmaseviyelist_399769_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"62b4b26d-11e3-47f5-9771-4bb22c2b9140","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":923160,"PropertyName":"value","Value":"seviye listesi"},{"Id":814413,"PropertyName":"label","Value":"YENİ"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"Veri Bulunamadı"},{"Id":7394711,"PropertyName":"title","Value":"ÜST EŞİK PUANI"},{"Id":2218112,"PropertyName":"value","Value":"[datafield:ustesikpuan]"},{"Id":2952620,"PropertyName":"title","Value":"SEVİYE ADI"},{"Id":9901558,"PropertyName":"value","Value":"[datafield:sinifadi]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.siniflandirmaseviyelist_399769_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        NAVIGATE: "",
	        SiniflandirmaDetaySearch: [],
	        SiniflandirmaDetayDeleteById: 0,

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("siniflandirmaseviyelist", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.SiniflandirmaSeviyeListPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("siniflandirmaseviyelist", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("siniflandirmaseviyelist", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.SiniflandirmaSeviyeListPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    SiniflandirmaSeviyeListPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId ?? this.props.screenInputs.sinifid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SiniflandirmaSeviyeList/SiniflandirmaSeviyeListPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.SiniflandirmaDetaySearch = result?.data.siniflandirmaDetaySearch;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SiniflandirmaSeviyeListPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	SiniflandirmaSeviyeListPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_399769 = this.state.SiniflandirmaDetaySearch;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		SiniflandirmaSeviyeListComponent_814413_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("SiniflandirmaSeviye", "SeviyeId", Guid.create().toString());
						KuikaAppManager.addToPageInputVariables("SiniflandirmaSeviye", "SinifId", this.props.screenInputs.SinifId ?? this.props.screenInputs.sinifid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "SiniflandirmaSeviyeList", "SiniflandirmaSeviye", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "486227", null, "right", null, "450px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		SiniflandirmaSeviyeListComponent_860665_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("SiniflandirmaSeviye", "SinifId", this.props.screenInputs.SinifId ?? this.props.screenInputs.sinifid);
						KuikaAppManager.addToPageInputVariables("SiniflandirmaSeviye", "SeviyeId", ReactSystemFunctions.value(this, "siniflandirmaseviyelist_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "SiniflandirmaSeviyeList", "SiniflandirmaSeviye", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "256479", null, "right", null, "450px", "", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    SiniflandirmaSeviyeListComponent_505853_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "siniflandirmaseviyelist_399769_value", "id"), "Guid"),
				siniflandirmaId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.SinifId ?? this.props.screenInputs.sinifid, "Guid")
        }

		KuikaAppManager.showSpinner(this);

            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"fe852656_41cb_0d69_f310_feba936ad060_confirmation",this.defaultML,"Are you sure you want to delete this record?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SiniflandirmaSeviyeList/SiniflandirmaSeviyeListComponent_505853_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SiniflandirmaDetayDeleteById = result?.data.siniflandirmaDetayDeleteById;
			stateVars.SiniflandirmaDetaySearch = result?.data.siniflandirmaDetaySearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SiniflandirmaSeviyeListComponent_505853_onClick1_();
                  resolve();
                });
          })

            }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SiniflandirmaSeviyeListComponent_505853_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.SiniflandirmaDetaySearch;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [486227, 256479] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.SiniflandirmaSeviyeListPageInit();
		}

    }
}
