import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IProtocol_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IProtocol_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	ServiceAll: any[];
	ServiceAll_dummy: any[];
	ProtokolSelectById: any[];
	ProtokolSelectById_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;
	DenormalizeProtokol: number;
	DenormalizeProtokol_dummy: number;
	NAVIGATE: any;
	NAVIGATE_dummy: any;


}

export class Protocol_ScreenBase extends React.PureComponent<IProtocol_ScreenProps, any> {

    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"9b8432ea-b1a9-41f3-a59c-23c4ace1ddea","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Protokol Form"},{"Id":166589,"PropertyName":"value","Value":"Tanım"},{"Id":8176846,"PropertyName":"placeholder","Value":"Type here..."},{"Id":795392,"PropertyName":"value","Value":"Geçerli Yaş Aralığı"},{"Id":776870,"PropertyName":"placeholder","Value":"En Küçük"},{"Id":199414,"PropertyName":"placeholder","Value":"En Büyük"},{"Id":9544,"PropertyName":"label","Value":"İLERİ"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        ServiceAll: [],
	        ProtokolSelectById: [],
	        SaveRecord: false,
	        DenormalizeProtokol: 0,
	        NAVIGATE: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("protocol", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.ProtocolPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("protocol", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("protocol", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.ProtocolPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
protocol_8176846_value: this.state.ProtokolSelectById?.at?.(0)?.tanim ?? undefined,
protocol_776870_value: this.state.ProtokolSelectById?.at?.(0)?.gecerliEnKucukYas ?? undefined,
protocol_199414_value: this.state.ProtokolSelectById?.at?.(0)?.gecerliEnBuyukYas ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    ProtocolPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Protocol/ProtocolPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.ServiceAll = result?.data.serviceAll;
		stateVars.ProtokolSelectById = result?.data.protokolSelectById;
		formVars.protocol_8176846_value = ReactSystemFunctions.toString(this, stateVars.ProtokolSelectById?.length > 0 ? stateVars.ProtokolSelectById[0]?.tanim : null);
		formVars.protocol_776870_value = ReactSystemFunctions.value(this, stateVars.ProtokolSelectById?.length > 0 ? stateVars.ProtokolSelectById[0]?.gecerliEnKucukYas : null);
		formVars.protocol_199414_value = ReactSystemFunctions.value(this, stateVars.ProtokolSelectById?.length > 0 ? stateVars.ProtokolSelectById[0]?.gecerliEnBuyukYas : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtocolPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	ProtocolPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.protocol_8176846_value = ReactSystemFunctions.toString(this, this.state.ProtokolSelectById?.length > 0 ? this.state.ProtokolSelectById[0]?.tanim : null);

			formVars.protocol_776870_value = ReactSystemFunctions.value(this, this.state.ProtokolSelectById?.length > 0 ? this.state.ProtokolSelectById[0]?.gecerliEnKucukYas : null);

			formVars.protocol_199414_value = ReactSystemFunctions.value(this, this.state.ProtokolSelectById?.length > 0 ? this.state.ProtokolSelectById[0]?.gecerliEnBuyukYas : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		ProtocolComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    ProtocolComponent_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protocol_776870_value", "value", "", "", "")), null),
				message: "*",
				formName: "protocol_776870_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protocol_199414_value", "value", "", "", "")), null),
				message: "*",
				formName: "protocol_199414_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				GecerliEnKucukYas_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protocol_776870_value", "value", "", "", "")), "number"),
				GecerliEnBuyukYas_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protocol_199414_value", "value", "", "", "")), "number"),
				Tanim_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol_8176846_value", "value", "", "", "")), "string"),
				protokolId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Protocol/ProtocolComponent_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			stateVars.DenormalizeProtokol = result?.data.denormalizeProtokol;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtocolComponent_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		ProtocolComponent_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("Protocol2", "Id", this.props.screenInputs.Id ?? this.props.screenInputs.id);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.ProtocolComponent_9544_onClick2_, "Protocol", "Protocol2", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "462594", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
		ProtocolComponent_9544_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }







    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [462594] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.ProtocolPageInit();
		}
		if (diId == 462594) {
			isErrorOccurred = await this.ProtocolComponent_9544_onClick2_();
            if (isErrorOccurred) return true;
		}

    }
}
