import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IProtokolAltBilesenF_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IProtokolAltBilesenF_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	NormmAll: any[];
	NormmAll_dummy: any[];
	ProtokolAltBilesenById: any[];
	ProtokolAltBilesenById_dummy: any[];
	ProtokolBilesenById: any[];
	ProtokolBilesenById_dummy: any[];
	AltBilesenByBilesenId: any[];
	AltBilesenByBilesenId_dummy: any[];
	AltBilesenTestleri: any[];
	AltBilesenTestleri_dummy: any[];
	AlternatifTestAdaylari: any[];
	AlternatifTestAdaylari_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;
	NAVIGATE: any;
	NAVIGATE_dummy: any;


}

export class ProtokolAltBilesenF_ScreenBase extends React.PureComponent<IProtokolAltBilesenF_ScreenProps, any> {
	protokolaltbilesenf_544298_value_kuikaSelectBoxRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"a1504390-369f-461f-aae7-b4f2117256cd","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Protokol Alt Bileşen Tanımı"},{"Id":439388,"PropertyName":"value","Value":"Alt Bileşen"},{"Id":544298,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":822712,"PropertyName":"value","Value":"Pay / Ağırlık"},{"Id":33057,"PropertyName":"placeholder","Value":"15"},{"Id":796292,"PropertyName":"value","Value":"Sıra No"},{"Id":489639,"PropertyName":"placeholder","Value":"15"},{"Id":9544,"PropertyName":"label","Value":"İLERİ"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.protokolaltbilesenf_544298_value_kuikaSelectBoxRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        NormmAll: [],
	        ProtokolAltBilesenById: [],
	        ProtokolBilesenById: [],
	        AltBilesenByBilesenId: [],
	        AltBilesenTestleri: [],
	        AlternatifTestAdaylari: [],
	        SaveRecord: false,
	        NAVIGATE: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenf", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.ProtokolAltBilesenFPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenf", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenf", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.ProtokolAltBilesenFPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
protokolaltbilesenf_544298_value: this.state.ProtokolAltBilesenById?.at?.(0)?.altBilesenId ?? undefined,
protokolaltbilesenf_33057_value: this.state.ProtokolAltBilesenById?.at?.(0)?.pay ?? undefined,
protokolaltbilesenf_489639_value: this.state.ProtokolAltBilesenById?.at?.(0)?.sira ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    ProtokolAltBilesenFPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid, "Guid"),
			Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "ProtokolAltBilesenF/ProtokolAltBilesenFPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.NormmAll = result?.data.normmAll;
		stateVars.ProtokolAltBilesenById = result?.data.protokolAltBilesenById;
		formVars.protokolaltbilesenf_544298_value = stateVars.ProtokolAltBilesenById?.length > 0 ? stateVars.ProtokolAltBilesenById[0]?.altBilesenId : null;
		formVars.protokolaltbilesenf_544298_options = stateVars.AltBilesenByBilesenId;
		formVars.protokolaltbilesenf_33057_value = ReactSystemFunctions.value(this, stateVars.ProtokolAltBilesenById?.length > 0 ? stateVars.ProtokolAltBilesenById[0]?.pay : null);
		formVars.protokolaltbilesenf_489639_value = ReactSystemFunctions.value(this, stateVars.ProtokolAltBilesenById?.length > 0 ? stateVars.ProtokolAltBilesenById[0]?.sira : null);
		stateVars.ProtokolBilesenById = result?.data.protokolBilesenById;
		stateVars.AltBilesenByBilesenId = result?.data.altBilesenByBilesenId;
		
		formVars.protokolaltbilesenf_544298_value = stateVars.ProtokolAltBilesenById?.length > 0 ? stateVars.ProtokolAltBilesenById[0]?.altBilesenId : null;
		formVars.protokolaltbilesenf_544298_options = stateVars.AltBilesenByBilesenId;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolAltBilesenFPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	ProtokolAltBilesenFPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			formVars.protokolaltbilesenf_544298_value = ReactSystemFunctions.toString(this, this.state.ProtokolAltBilesenById?.length > 0 ? this.state.ProtokolAltBilesenById[0]?.altBilesenId : null);

			
			stateVars.dataSource_544298 = this.state.AltBilesenByBilesenId;
			stateVars.dataSource_544298 = this.state.AltBilesenByBilesenId;
			formVars.protokolaltbilesenf_33057_value = ReactSystemFunctions.value(this, this.state.ProtokolAltBilesenById?.length > 0 ? this.state.ProtokolAltBilesenById[0]?.pay : null);

			formVars.protokolaltbilesenf_489639_value = ReactSystemFunctions.value(this, this.state.ProtokolAltBilesenById?.length > 0 ? this.state.ProtokolAltBilesenById[0]?.sira : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		ProtokolAltBilesenFComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    ProtokolAltBilesenFComponent_544298_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				altBilesenId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protokolaltbilesenf_544298_value", "value", "AltBilesenByBilesenId", "id", "id")), "Guid"),
				AltBilesenId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protokolaltbilesenf_544298_value", "value", "AltBilesenByBilesenId", "id", "id")), "Guid"),
				varsayilanTestId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.ProtokolAltBilesenById?.length > 0 ? this.state.ProtokolAltBilesenById[0]?.varsayilanTestId : null), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "ProtokolAltBilesenF/ProtokolAltBilesenFComponent_544298_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.AltBilesenTestleri = result?.data.altBilesenTestleri;
			stateVars.AlternatifTestAdaylari = result?.data.alternatifTestAdaylari;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    ProtokolAltBilesenFComponent_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protokolaltbilesenf_33057_value", "value", "", "", "")), null),
				message: "*",
				formName: "protokolaltbilesenf_33057_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protokolaltbilesenf_489639_value", "value", "", "", "")), null),
				message: "*",
				formName: "protokolaltbilesenf_489639_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protokolaltbilesenf_544298_value", "value", "AltBilesenByBilesenId", "id", "")), null),
				message: "*",
				formName: "protokolaltbilesenf_544298_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid, "Guid"),
				Pay_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protokolaltbilesenf_33057_value", "value", "", "", "")), "number"),
				ProtokolBilesenId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid, "Guid"),
				Sira_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protokolaltbilesenf_489639_value", "value", "", "", "")), "number"),
				AltBilesenId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protokolaltbilesenf_544298_value", "value", "AltBilesenByBilesenId", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "ProtokolAltBilesenF/ProtokolAltBilesenFComponent_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolAltBilesenFComponent_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		ProtokolAltBilesenFComponent_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("ProtokolAltBilesenF2", "ProtokolBilesenId", this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid);
						KuikaAppManager.addToPageInputVariables("ProtokolAltBilesenF2", "ProtokolId", this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid);
						KuikaAppManager.addToPageInputVariables("ProtokolAltBilesenF2", "ProtokolAltBilesenId", this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.ProtokolAltBilesenFComponent_9544_onClick2_, "ProtokolAltBilesenF", "ProtokolAltBilesenF2", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "502592", null, "right", null, "400px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
		ProtokolAltBilesenFComponent_9544_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }







    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [502592] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.ProtokolAltBilesenFPageInit();
		}
		if (diId == 502592) {
			isErrorOccurred = await this.ProtokolAltBilesenFComponent_9544_onClick2_();
            if (isErrorOccurred) return true;
		}

    }
}
