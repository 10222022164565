import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IProfiles_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IProfiles_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	KisiProfilleri: any[];
	KisiProfilleri_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	CopyToClipboard: any;
	CopyToClipboard_dummy: any;
	ProfilDeleteById: number;
	ProfilDeleteById_dummy: number;
	isComp398098Visible: 'visible' | 'hidden';
	isComp752131Visible: 'visible' | 'hidden';


}

export class Profiles_ScreenBase extends React.PureComponent<IProfiles_ScreenProps, any> {
	profiles_399769_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"505b3d5f-1e9c-4893-a798-c004ab6076b0","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":923160,"PropertyName":"value","Value":"PROFİL LİSTESİ"},{"Id":814413,"PropertyName":"label","Value":"EKLE"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":2977226,"PropertyName":"title","Value":"Kurum"},{"Id":7829763,"PropertyName":"value","Value":"[datafield:kurumadi]"},{"Id":3249157,"PropertyName":"title","Value":"Rolename"},{"Id":1235982,"PropertyName":"value","Value":"[datafield:roledesc]"},{"Id":398098,"PropertyName":"value","Value":"(Freelance)"},{"Id":269295,"PropertyName":"title","Value":"Hesap No"},{"Id":549445,"PropertyName":"value","Value":"[datafield:hesapid]"},{"Id":147764,"PropertyName":"title","Value":"Kredi"},{"Id":106805,"PropertyName":"value","Value":"[datafield:bakiye]"},{"Id":540758,"PropertyName":"title","Value":"Expiration"},{"Id":789071,"PropertyName":"value","Value":"[datafield:expiration]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.profiles_399769_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        KisiProfilleri: [],
	        NAVIGATE: "",
	        CopyToClipboard: "",
	        ProfilDeleteById: 0,
	        isComp398098Visible: 'hidden',
	        isComp752131Visible: 'hidden',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("profiles", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.ProfilesPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("profiles", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("profiles", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.ProfilesPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    ProfilesPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			kisiId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Profiles/ProfilesPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.KisiProfilleri = result?.data.kisiProfilleri;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProfilesPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	ProfilesPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_399769 = this.state.KisiProfilleri;
			stateVars.isComp398098Visible = ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "profiles_399769_value", "isFreelance"), true)) === true ? "visible" : "hidden")
			stateVars.isComp752131Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "profiles_399769_value", "hesapId"), null)) === true ? "visible" : "hidden")

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		ProfilesComponent_321766_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		ProfilesComponent_814413_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("Profile", "ProfileId", Guid.create().toString());
						KuikaAppManager.addToPageInputVariables("Profile", "KisiId", this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Profiles", "Profile", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "486227", null, "right", null, "450px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		ProfilesComponent_752131_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.CopyToClipboard = await ReactSystemFunctions.copyToClipboard(ReactSystemFunctions.value(this, "profiles_399769_value", "hesapId"));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		ProfilesComponent_961908_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("KrediYukle", "KimeHesapId", ReactSystemFunctions.value(this, "profiles_399769_value", "hesapId"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.ProfilesComponent_961908_onClick1_, "Profiles", "KrediYukle", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "387541", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
    ProfilesComponent_961908_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				myRole_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null), "string"),
				lang_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.lang : null), "string"),
				kisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid, "Guid")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Profiles/ProfilesComponent_961908_onClick1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KisiProfilleri = result?.data.kisiProfilleri;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProfilesComponent_961908_onClick2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		ProfilesComponent_961908_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.KisiProfilleri;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





		ProfilesComponent_292996_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("Profile", "ProfileId", ReactSystemFunctions.value(this, "profiles_399769_value", "id"));
						KuikaAppManager.addToPageInputVariables("Profile", "KisiId", this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Profiles", "Profile", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "116990", null, "right", null, "450px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    ProfilesComponent_505853_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "profiles_399769_value", "id"), "Guid"),
				myRole_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolename : null), "string"),
				lang_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.lang : null), "string"),
				kisiId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "profiles_399769_value", "kisiId"), "Guid")
        }

		KuikaAppManager.showSpinner(this);

            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"fe852656_41cb_0d69_f310_feba936ad060_confirmation",this.defaultML,"Are you sure you want to delete this record?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Profiles/ProfilesComponent_505853_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ProfilDeleteById = result?.data.profilDeleteById;
			stateVars.KisiProfilleri = result?.data.kisiProfilleri;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProfilesComponent_505853_onClick1_();
                  resolve();
                });
          })

            }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		ProfilesComponent_505853_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.KisiProfilleri;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [486227, 387541, 116990] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.ProfilesPageInit();
		}
		if (diId == 387541) {
			isErrorOccurred = await this.ProfilesComponent_961908_onClick1_();
            if (isErrorOccurred) return true;
		}

    }
}
