import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IOrganisations_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IOrganisations_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	MyContext: any[];
	MyContext_dummy: any[];
	KurumCount: any[];
	KurumCount_dummy: any[];
	KurumSearch: any[];
	KurumSearch_dummy: any[];
	KurumTipiAll: any[];
	KurumTipiAll_dummy: any[];
	SetValueOf: any;
	SetValueOf_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	OpenInBrowser: any;
	OpenInBrowser_dummy: any;
	CopyToClipboard: any;
	CopyToClipboard_dummy: any;
	KurumDeleteById: number;
	KurumDeleteById_dummy: number;
	isComp362406Visible: 'visible' | 'hidden';
	isComp697329Visible: 'visible' | 'hidden';

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class Organisations_ScreenBase extends React.PureComponent<IOrganisations_ScreenProps, any> {
	organisations_362406_value_kuikaSelectBoxRef: React.RefObject<any>;
	organisations_399769_value_kuikaTableRef: React.RefObject<any>;
	organisations_172599_value_kuikaPaginationRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"ce085a2c-a58a-48b0-a95e-53f8f63e43ba","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":740662,"PropertyName":"value","Value":"KURUMLAR"},{"Id":362406,"PropertyName":"placeholder","Value":"Kurum Tipi Filtresi..."},{"Id":416063,"PropertyName":"placeholder","Value":"Arama..."},{"Id":814413,"PropertyName":"label","Value":"YENİ KURUM"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":5608801,"PropertyName":"title","Value":"Kod"},{"Id":5690638,"PropertyName":"value","Value":"[datafield:kod]"},{"Id":949301,"PropertyName":"title","Value":"Kurum Tipi"},{"Id":397392,"PropertyName":"value","Value":"[datafield:kurumtipi]"},{"Id":854391,"PropertyName":"title","Value":"Ünvan"},{"Id":919531,"PropertyName":"value","Value":"[datafield:unvani]"},{"Id":4374495,"PropertyName":"title","Value":"Telefon"},{"Id":8380289,"PropertyName":"value","Value":"[datafield:phonenumber]"},{"Id":4769393,"PropertyName":"title","Value":"Web Adresi"},{"Id":7313665,"PropertyName":"value","Value":"[datafield:webadresi]"},{"Id":5464893,"PropertyName":"title","Value":"Hesap Numarası"},{"Id":353410,"PropertyName":"value","Value":"[datafield:hesapid]"},{"Id":814868,"PropertyName":"title","Value":"Lisans Geçerlilik"},{"Id":827255,"PropertyName":"value","Value":"[datafield:licenceexpiry]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.organisations_362406_value_kuikaSelectBoxRef = React.createRef();
		this.organisations_399769_value_kuikaTableRef = React.createRef();
		this.organisations_172599_value_kuikaPaginationRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        MyContext: [],
	        KurumCount: [],
	        KurumSearch: [],
	        KurumTipiAll: [],
	        SetValueOf: "",
	        NAVIGATE: "",
	        OpenInBrowser: "",
	        CopyToClipboard: "",
	        KurumDeleteById: 0,
	        isComp362406Visible: 'hidden',
	        isComp697329Visible: 'hidden',

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("organisations", "master_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.OrganisationsPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("organisations", "master_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("organisations", "master_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.OrganisationsPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.OrganisationsPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
organisations_362406_value: this.props.screenInputs.role ?? this.props.screenInputs.role
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    OrganisationsPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
			kurumTipiFilter_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
			searchFor_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
			currentpage_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "current", "", "", "")), "number"),
			pagesize_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "pageSize", "", "", "")), "number")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Organisations/OrganisationsPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.KurumCount = result?.data.kurumCount;
		stateVars.KurumSearch = result?.data.kurumSearch;
		
		stateVars.KurumTipiAll = result?.data.kurumTipiAll;
		
		formVars.organisations_362406_options = stateVars.KurumTipiAll;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.OrganisationsPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	OrganisationsPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_362406 = this.state.KurumTipiAll;
			stateVars.dataSource_362406 = this.state.KurumTipiAll;
			stateVars.isComp362406Visible = ((ReactSystemFunctions.isEmpty(this.props.screenInputs.role ?? this.props.screenInputs.role, null)) === true ? "visible" : "hidden")
			
			stateVars.dataSource_399769 = this.state.KurumSearch;
			stateVars.isComp697329Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "organisations_399769_value", "hesapId"), null)) === true ? "visible" : "hidden")
			formVars.organisations_172599_total = ReactSystemFunctions.value(this, this.state.KurumCount?.length > 0 ? this.state.KurumCount[0]?.count : null);


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    OrganisationsComponent_362406_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.lastChosenProfileId : null), "Guid"),
				kurumTipiFilter_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "organisations_362406_value", "value", "KurumTipiAll", "kod", "kod")), "string"),
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
				currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "current", "", "", "")), "number"),
				pagesize_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Organisations/OrganisationsComponent_362406_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KurumSearch = result?.data.kurumSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.OrganisationsComponent_362406_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		OrganisationsComponent_362406_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.KurumSearch;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




		OrganisationsComponent_416063_onPressEnter = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "organisations_172599_value", 1, null);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.OrganisationsComponent_416063_onPressEnter1_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    OrganisationsComponent_416063_onPressEnter1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
				profileId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.lastChosenProfileId : null), "Guid"),
				kurumTipiFilter_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "pageSize", "", "", "")), "number")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Organisations/OrganisationsComponent_416063_onPressEnter1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KurumCount = result?.data.kurumCount;
			stateVars.KurumSearch = result?.data.kurumSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.OrganisationsComponent_416063_onPressEnter2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		OrganisationsComponent_416063_onPressEnter2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.KurumSearch;
				formVars.organisations_172599_total = ReactSystemFunctions.value(this, this.state.KurumCount?.length > 0 ? this.state.KurumCount[0]?.count : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





		OrganisationsComponent_814413_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("Organisation", "Id", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Organisations", "Organisation", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "486227", null, "right", null, "1100px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		OrganisationsComponent_7313665_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(ReactSystemFunctions.value(this, "organisations_399769_value", "webAdresi"), "newtab");

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		OrganisationsComponent_697329_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.CopyToClipboard = await ReactSystemFunctions.copyToClipboard(ReactSystemFunctions.value(this, "organisations_399769_value", "hesapId"));

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		OrganisationsComponent_935519_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("OrganisationCard", "KurumId", ReactSystemFunctions.value(this, "organisations_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Organisations", "OrganisationCard", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		OrganisationsComponent_860665_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("Organisation", "Id", ReactSystemFunctions.value(this, "organisations_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Organisations", "Organisation", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "256479", null, "right", null, "1100px", "", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    OrganisationsComponent_505853_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "organisations_399769_value", "id"), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
				profileId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.lastChosenProfileId : null), "Guid"),
				kurumTipiFilter_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				searchFor_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
				currentpage_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "current", "", "", "")), "number"),
				pagesize_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);

            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"fe852656_41cb_0d69_f310_feba936ad060_confirmation",this.defaultML,"Are you sure you want to delete this record?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Organisations/OrganisationsComponent_505853_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KurumDeleteById = result?.data.kurumDeleteById;
			stateVars.KurumCount = result?.data.kurumCount;
			stateVars.KurumSearch = result?.data.kurumSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.OrganisationsComponent_505853_onClick1_();
                  resolve();
                });
          })

            }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		OrganisationsComponent_505853_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.KurumSearch;
				formVars.organisations_172599_total = ReactSystemFunctions.value(this, this.state.KurumCount?.length > 0 ? this.state.KurumCount[0]?.count : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    OrganisationsComponent_172599_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
				profileId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.lastChosenProfileId : null), "Guid"),
				kurumTipiFilter_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "organisations_362406_value", "value", "KurumTipiAll", "kod", "kod")), "string"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "organisations_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Organisations/OrganisationsComponent_172599_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KurumCount = result?.data.kurumCount;
			stateVars.KurumSearch = result?.data.kurumSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.OrganisationsComponent_172599_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		OrganisationsComponent_172599_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_399769 = this.state.KurumSearch;
				formVars.organisations_172599_total = ReactSystemFunctions.value(this, this.state.KurumCount?.length > 0 ? this.state.KurumCount[0]?.count : null);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [486227, , 256479] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.OrganisationsPageInit();
		}

    }
}
