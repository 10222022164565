import {
  HIDE_IYZICO_DRAWER,
  HIDE_PHOTO_TAKE,
  HIDE_STRIPE_DRAWER,
  IyzicoDrawerActionTypes,
  PhotoTakeActionTypes,
  PhotoTakeState,
  SHOW_IYZICO_DRAWER,
  SHOW_PHOTO_TAKE,
  SHOW_STRIPE_DRAWER,
  StripeDrawerActionTyples
} from "./types";

const initialState: PhotoTakeState = {
  visible: false
};
const initialStripeState: StripeDrawerState = {
  visible: false,
  payload: undefined,
  callback: undefined,
  screen: undefined,
  futureUse: undefined
};
const initialIyzicoState: IyzicoDrawerState = {
  visible: false,
  payload: undefined,
  callback: undefined,
  screen: undefined
};
export interface StripeDrawerState {
  visible: boolean;
  payload: any;
  callback: any;
  screen: any;
  futureUse: any;
}
export interface IyzicoDrawerState {
  visible: boolean;
  payload: any;
  callback: any;
  screen: any;
}

export const photoTakeReducer = (state = initialState, action: PhotoTakeActionTypes): PhotoTakeState => {
  switch (action.type) {
    case SHOW_PHOTO_TAKE:
      return {
        visible: true
      };
    case HIDE_PHOTO_TAKE:
      return {
        visible: false
      };
    default:
      return state;
  }
};
export const stripeDrawerReducer = (
  state = initialStripeState,
  action: StripeDrawerActionTyples
): StripeDrawerState => {
  switch (action.type) {
    case SHOW_STRIPE_DRAWER:
      return {
        visible: true,
        payload: action.payload,
        callback: action.callback,
        screen: action.screen,
        futureUse: action.futureUse
      };
    case HIDE_STRIPE_DRAWER:
      return {
        visible: false,
        payload: action.payload,
        callback: action.callback,
        screen: action.screen,
        futureUse: state.futureUse
      };
    default:
      return state;
  }
};

export const iyzicoDrawerReducer = (state = initialIyzicoState, action: IyzicoDrawerActionTypes): IyzicoDrawerState => {
  switch (action.type) {
    case SHOW_IYZICO_DRAWER:
      return {
        visible: true,
        payload: action.payload,
        callback: action.callback,
        screen: action.screen
      };
    case HIDE_IYZICO_DRAWER:
      return {
        visible: false,
        payload: action.payload,
        callback: action.callback,
        screen: action.screen
      };
    default:
      return state;
  }
};
