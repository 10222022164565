import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IRandevuSelfService_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IRandevuSelfService_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	RandevuHizmetler: any[];
	RandevuHizmetler_dummy: any[];
	ChangeEnabledOf: any;
	ChangeEnabledOf_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	isComp341303Enabled: 'enabled' | 'disabled';


}

export class RandevuSelfService_ScreenBase extends React.PureComponent<IRandevuSelfService_ScreenProps, any> {
	randevuselfservice_502333_value_kuikaFlexGridRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"1e2eedc1-b872-4fcd-8119-fe576e1c6fdb","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Randevu"},{"Id":667050,"PropertyName":"value","Value":"Randevu almak istediğiniz hizmeti seçiniz..."},{"Id":502333,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":345849,"PropertyName":"value","Value":"[datafield:title]"},{"Id":652630,"PropertyName":"value","Value":"[datafield:aciklama]"},{"Id":842853,"PropertyName":"value","Value":"Süre"},{"Id":875569,"PropertyName":"value","Value":"[datafield:sure]"},{"Id":218191,"PropertyName":"value","Value":"dk"},{"Id":341303,"PropertyName":"label","Value":"İLERİ"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.randevuselfservice_502333_value_kuikaFlexGridRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        RandevuHizmetler: [],
	        ChangeEnabledOf: "",
	        NAVIGATE: "",
	        isComp341303Enabled: 'enabled',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.RandevuSelfServicePageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.RandevuSelfServicePageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      

    RandevuSelfServicePageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

        let localVar = {
			davetId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid, "Guid"),
			seciliHizmetId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
			seciliMerkezId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
			seciliEgitmenId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSelfService/RandevuSelfServicePageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.RandevuHizmetler = result?.data.randevuHizmetler;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfServicePageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	RandevuSelfServicePageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfServicePageInit2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	RandevuSelfServicePageInit2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, this.state.RandevuHizmetler?.length > 0 ? this.state.RandevuHizmetler[0]?.seciliHizmetId : null), null)) {
            
			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "enabled");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfServicePageInit3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfServicePageInit3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
	RandevuSelfServicePageInit3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations)) return true;

			
			stateVars.dataSource_502333 = this.state.RandevuHizmetler;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }






		RandevuSelfServiceComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    RandevuSelfServiceComponent_657215_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				davetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid, "Guid"),
				seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "randevuselfservice_502333_value", "id"), "Guid"),
				seciliMerkezId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				seciliEgitmenId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSelfService/RandevuSelfServiceComponent_657215_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuHizmetler = result?.data.randevuHizmetler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfServiceComponent_657215_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSelfServiceComponent_657215_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfServiceComponent_657215_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
		RandevuSelfServiceComponent_657215_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, this.state.RandevuHizmetler?.length > 0 ? this.state.RandevuHizmetler[0]?.seciliHizmetId : null), null)) {
            
				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "enabled");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfServiceComponent_657215_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfServiceComponent_657215_onClick3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuSelfServiceComponent_657215_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				
				stateVars.dataSource_502333 = this.state.RandevuHizmetler;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






		RandevuSelfServiceComponent_341303_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSelfService2", "HizmetId", ReactSystemFunctions.value(this, this.state.RandevuHizmetler?.length > 0 ? this.state.RandevuHizmetler[0]?.seciliHizmetId : null));
						KuikaAppManager.addToPageInputVariables("RandevuSelfService2", "DavetiyeId", this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid);
						KuikaAppManager.addToPageInputVariables("RandevuSelfService2", "RandevuId", this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid);
						KuikaAppManager.addToPageInputVariables("RandevuSelfService2", "DanisanKisiId", this.props.screenInputs.DanisanKisiId ?? this.props.screenInputs.danisankisiid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.RandevuSelfServiceComponent_341303_onClick1_, "RandevuSelfService", "RandevuSelfService2", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "19796", null, "right", null, "80vw", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
		RandevuSelfServiceComponent_341303_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations)) return true;

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [19796] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.RandevuSelfServicePageInit();
		}
		if (diId == 19796) {
			isErrorOccurred = await this.RandevuSelfServiceComponent_341303_onClick1_();
            if (isErrorOccurred) return true;
		}

    }
}
